import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { Constants } from "../../constants";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import AddStudentImage from "../../assets/icons/addstudenticon.png";
import Act from "./activateschool.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const ActivateSchool = (props) => {
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.value);
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [activeSchools, setActiveSchools] = useState([]);
  const [deactiveSchool, setDeactiveSchools] = useState([]);
  const [activeSelect, setActiveSelect] = useState("Select School");
  const [deactiveSelect, setDeactiveSelect] = useState("Select School");

  // Useeffect for api call
  useEffect(() => {
    getSchoolData();

    //eslint-disable-next-line
  }, []);

  const getSchoolData = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/schools/all_school_codes`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        let active = [];
        let deactive = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i]["is_active"] === true) {
            active.push(result.data[i]);
          } else {
            deactive.push(result.data[i]);
          }
        }
        setActiveSchools(active);
        setDeactiveSchools(deactive);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const activateSchool = () => {
    setSpin(true);

    var config = {
      method: "PUT",
      url: `${Constants.api_url}/schools/reactivate/${activeSelect.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        setSpin(false);
        swal({
          title: "Success",
          text: "School activated successfully",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setActiveSelect("Select School");
            setDeactiveSelect("Select School");
            getSchoolData();
          }
        });
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const deactivateSchool = () => {
    setSpin(true);

    var config = {
      method: "PUT",
      url: `${
        Constants.api_url
      }/schools/deactivate/${deactiveSelect.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        setSpin(false);
        swal({
          title: "Success",
          text: "School deactivated successfully",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setActiveSelect("Select School");
            setDeactiveSelect("Select School");
            getSchoolData();
          }
        });
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={Act.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Act.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Act.backbutton} />
      </div>
      <div className={Act.headingholder}>
        <img
          className={Act.addstudenticon}
          src={AddStudentImage}
          alt="addstudenticon"
        />
        <h3 className={Act.heading}>Activate/Deactive Schools</h3>
      </div>
      <div className={Act.activeholder}>
        <div className={Act.active}>
          <h4 className={Act.heading}>Activate Schools</h4>
          <select
            className={Act.selectholder}
            value={activeSelect}
            onChange={(e) => setActiveSelect(e.target.value)}
          >
            <option selected disabled>
              Select School
            </option>
            {deactiveSchool.map((value, i) => {
              return (
                <option
                  key={i}
                  value={value.school_code}
                  className={Act.selectschool}
                >
                  {value.school_name}
                </option>
              );
            })}
          </select>
          <button
            className={Act.savebutton}
            disabled={activeSelect === "Select Schools" ? true : false}
            onClick={() => activateSchool()}
          >
            Activate
          </button>
        </div>
        <div className={Act.deactive}>
          <h4 className={Act.heading}>Deactivate Schools</h4>
          <select
            className={Act.selectholder}
            value={deactiveSelect}
            onChange={(e) => setDeactiveSelect(e.target.value)}
          >
            <option selected disabled>
              Select School
            </option>
            {activeSchools.map((value, i) => {
              return (
                <option
                  key={i}
                  value={value.school_code}
                  className={Act.selectschool}
                >
                  {value.school_name}
                </option>
              );
            })}
          </select>
          <button
            className={Act.savebutton}
            onClick={() => deactivateSchool()}
            disabled={deactiveSelect === "Select Schools" ? true : false}
          >
            Deactivate
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivateSchool;

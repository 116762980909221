// Dependencies imports
import React, { useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import FormData from "form-data";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";

// Local imports
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Style imports
import Add from "./addNotice.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const AddNotice = (props) => {
  const { handleClose, getNoticeBoard } = props;
  const [spin, setSpin] = useState(false);
  const [title, setTitle] = useState("");
  const [noticeBody, setNoiceBody] = useState("");
  const [level, setLevel] = useState("");
  const [filetype, setFiletype] = useState("");
  const [titleError, setTitleError] = useState("");
  const [noticeBodyError, setNoiceBodyError] = useState("");
  const [levelError, setLevelError] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileErr, setSelectedFileErr] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();

  const handleOnChange = (event) => {
    let files = event.target.files;
    console.log("files", files);
    console.log("file type", files[0]["type"]);
    setFiletype(files[0]["type"]);
    if (
      files[0]["type"] !== "image/png" &&
      files[0]["type"] !== "image/jpeg" &&
      files[0]["type"] !== "image/jpg"
    ) {
      setSelectedFileErr("Please attach a file of png, jpeg or jpg formats");
    } else {
      setSelectedFileErr("");
    }
    setSelectedFile(files[0]);
  };

  const postNotice = () => {
    setSpin(true);
    for (let i = 0; i < selectedClasses.length; i++) {
      var formdata = new FormData();
      formdata.append("created_by", userDetails.email);
      formdata.append("school_code", userDetails.school_code);
      formdata.append("level", selectedClasses[i]);
      formdata.append("title", title);
      formdata.append("body", noticeBody);
      if (selectedFile !== "") {
        formdata.append("attachment", selectedFile, selectedFile.name);
      }

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${Constants.api_url}/notices/add`, requestOptions)
        .then((result) => {
          console.log("result", result);
          if (i === selectedClasses.length - 1) {
            setSpin(false);
            swal({
              title: "Success",
              text: "Notice Added Successfully",
              icon: "success",
              dangerMode: false,
              closeOnClickOutside: false,
            }).then((willDelete) => {
              if (willDelete) {
                getNoticeBoard();
                handleClose();
              }
            });
          }
        })
        .catch((error) => {
          setSpin(false);
          console.log("error", error);
          setSpin(false);
          swal({
            title: "Oops!",
            text: "Something went wrong",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        });
    }
  };

  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setTitleError("");
    setNoiceBodyError("");
    setLevelError("");
    setSelectedFileErr("");

    if (title === "" || title === null || title.trim() === "") {
      formIsValid = false;
      setTitleError("Please enter notice title");
      return;
    }
    if (noticeBody === "" || noticeBody === null || noticeBody.trim() === "") {
      formIsValid = false;
      setNoiceBodyError("Please enter notice body");
      return;
    }
    if (selectedClasses.length === 0) {
      formIsValid = false;
      setLevelError("Please select a class");
      return;
    }

    if (
      filetype !== "image/png" &&
      filetype !== "image/jpeg" &&
      filetype !== "image/jpg"
    ) {
      formIsValid = false;
      setSelectedFileErr("Please attach a file of png, jpeg or jpg formats");
    }

    if (formIsValid === true) {
      console.log("form is valid");
      postNotice();
      return;
    }
  };

  const handleSelection = (event) => {
    let updatedList = [...selectedClasses];
    if (event.target.checked) {
      updatedList = [...selectedClasses, event.target.value];
      if (
        updatedList.includes("playgroup") &&
        updatedList.includes("nursery") &&
        updatedList.includes("lkg") &&
        updatedList.includes("ukg")
      ) {
        setSelectAll(true);
      }
    } else {
      updatedList.splice(selectedClasses.indexOf(event.target.value), 1);
      if (
        !updatedList.includes("playgroup") ||
        !updatedList.includes("nursery") ||
        !updatedList.includes("lkg") ||
        !updatedList.includes("ukg")
      ) {
        setSelectAll(false);
      }
    }
    setSelectedClasses(updatedList);
  };

  return (
    <div className={Add.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Add.topbar}>
        <Close style={{ cursor: "pointer" }} onClick={handleClose} />
      </div>
      <div className={Add.heading}>Add Notice</div>
      <div className={Add.formholder}>
        <div className={Add.inputblock}>
          <label className={Add.formlabel}>Notice Title</label>
          <input
            type="text"
            className={Add.inputtext}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <h4 className={Add.errormessage}>{titleError}</h4>
        <div className={Add.inputblock}>
          <label className={Add.formlabel}>Notice Body</label>
          <textarea
            col="5"
            className={Add.inputtextarea}
            value={noticeBody}
            onChange={(event) => setNoiceBody(event.target.value)}
          />
        </div>
        <h4 className={Add.errormessage}>{noticeBodyError}</h4>
        <div className={Add.inputblock}>
          <label className={Add.formlabel}>Student Class</label>
          <div className={Add.classcheckholder}>
            <div className={Add.selectallholder}>
              <input
                type="checkbox"
                className={Add.checkbox}
                checked={selectAll}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectAll(true);
                    let tempChecked = [...selectedClasses];
                    if (tempChecked.includes("playgroup") === false) {
                      tempChecked.push("playgroup");
                    }
                    if (tempChecked.includes("nursery") === false) {
                      tempChecked.push("nursery");
                    }
                    if (tempChecked.includes("lkg") === false) {
                      tempChecked.push("lkg");
                    }
                    if (tempChecked.includes("ukg") === false) {
                      tempChecked.push("ukg");
                    }
                    setSelectedClasses(tempChecked);
                  } else {
                    setSelectAll(false);
                    let tempChecked = [...selectedClasses];
                    if (tempChecked.includes("playgroup")) {
                      for (let i = 0; i < tempChecked.length; i++) {
                        if (tempChecked[i] === "playgroup") {
                          tempChecked.splice(i, 1);
                        }
                      }
                    }
                    if (tempChecked.includes("nursery")) {
                      for (let i = 0; i < tempChecked.length; i++) {
                        if (tempChecked[i] === "nursery") {
                          tempChecked.splice(i, 1);
                        }
                      }
                    }
                    if (tempChecked.includes("lkg")) {
                      for (let i = 0; i < tempChecked.length; i++) {
                        if (tempChecked[i] === "lkg") {
                          tempChecked.splice(i, 1);
                        }
                      }
                    }
                    if (tempChecked.includes("ukg")) {
                      for (let i = 0; i < tempChecked.length; i++) {
                        if (tempChecked[i] === "ukg") {
                          tempChecked.splice(i, 1);
                        }
                      }
                    }
                    setSelectedClasses(tempChecked);
                  }
                }}
              />
              <h4 className={Add.checklabel}>Select all</h4>
            </div>
            <div className={Add.otherholders}>
              <div className={Add.selectallholder}>
                <input
                  type="checkbox"
                  className={Add.checkbox}
                  value={"playgroup"}
                  checked={selectedClasses.includes("playgroup") ? true : false}
                  onChange={(e) => handleSelection(e)}
                />
                <h4 className={Add.checklabel}>Playgroup</h4>
              </div>
              <div className={Add.selectallholder}>
                <input
                  type="checkbox"
                  className={Add.checkbox}
                  value={"nursery"}
                  onChange={(e) => handleSelection(e)}
                  checked={selectedClasses.includes("nursery") ? true : false}
                />
                <h4 className={Add.checklabel}>Nursery</h4>
              </div>
              <div className={Add.selectallholder}>
                <input
                  type="checkbox"
                  className={Add.checkbox}
                  value={"lkg"}
                  onChange={(e) => handleSelection(e)}
                  checked={selectedClasses.includes("lkg") ? true : false}
                />
                <h4 className={Add.checklabel}>LKG</h4>
              </div>
              <div className={Add.selectallholder}>
                <input
                  type="checkbox"
                  className={Add.checkbox}
                  value={"ukg"}
                  onChange={(e) => handleSelection(e)}
                  checked={selectedClasses.includes("ukg") ? true : false}
                />
                <h4 className={Add.checklabel}>UKG</h4>
              </div>
            </div>
          </div>
          <h4 className={Add.errormessage}>{levelError}</h4>
        </div>
        <div className={Add.inputblock}>
          <label className={Add.formlabel}>Image Attachement</label>
          <input
            type="file"
            onChange={(event) => handleOnChange(event)}
            className={Add.fileinput}
          />
        </div>
        <h4 className={Add.errormessage}>{selectedFileErr}</h4>
      </div>
      <div className={Add.submitbuttonholder}>
        <button className={Add.submitbutton} onClick={() => validateForm()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddNotice;

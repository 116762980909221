import React, { useState, useEffect } from "react";
import FD from "./feesdirectory.module.scss";
import feesIcon from "../../assets/icons/fees.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusicon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants";
import qs from "qs";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import swal from "sweetalert";

import Ad from "./addstudent.module.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Feestemplate() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.value);
  const [spin, setSpin] = useState(true);
  const [templates, settemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editopen, seteditOpen] = useState(false);
  const handleeditOpen = () => seteditOpen(true);
  const handleeditClose = () => {
    seteditOpen(false);
    setFeesItems([{ items: "", amount: "" }]);
    setTemplateName("");
    setTemplateCode("");
  };
  const [templateName, setTemplateName] = useState("");
  const [templateCode, setTemplateCode] = useState("");
  const [feesItems, setFeesItems] = useState([{ item_name: "", amount: "" }]);

  useEffect(() => {
    if (userDetails.school_code) {
      getDataGridData();
    }
  }, []);

  const getDataGridData = () => {
    setSpin(true);
    var data = qs.stringify({});

    var config = {
      method: "GET",
      url: `${Constants.api_url}/feestemplate/?school_code=${userDetails.school_code}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        console.log(result.data);
        settemplates(result.data);
      })
      .catch(function (error) {
        setSpin(false);
        console.log(error.message);
      });
  };

  const handleFeesItemChange = (index, key, value) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems[index][key] = value;
    setFeesItems(updatedFeesItems);
  };

  const addFeesItem = () => {
    setFeesItems([...feesItems, { item_name: "", amount: "" }]);
  };
  const deleteFeesItem = (index) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems.splice(index, 1);
    setFeesItems(updatedFeesItems);
  };
  const handleTemplate = (item) => {
    setTemplateName(item.template_name);
    setTemplateCode(item.template_code);
    setFeesItems(item.fees_items);
    handleeditOpen();
  };

  const handleFormSubmit = () => {
    const formData = {
      template_name: templateName,
      template_code: templateCode,
      fees_items: feesItems,
    };
    axios
      .post(
        `${Constants.api_url}/feestemplate/create-new?school_code=${userDetails.school_code}`,
        formData
      )
      .then(function (result) {
        console.log(result.data);
        swal({
          title: "Success",
          text: "Successfully added",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setFeesItems([{ items: "", amount: "" }]);
            setTemplateName("");
            setTemplateCode("");
          }
        });
        getDataGridData();
        handleClose();
      })
      .catch(function (error) {
        swal({
          title: "Warning!",
          text: "Please enter valid details",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const handleDelete = () => {
    axios
      .delete(
        `${Constants.api_url}/feestemplate/delete-template/${templateCode}`
      )
      .then(function (result) {
        swal({
          title: "Success",
          text: "Deleted Successfully",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        getDataGridData();
        handleeditClose();
      })
      .catch(function (error) {
        console.error(error);
        swal({
          title: "Warning!",
          text: "Please enter valid details",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const handleUpdate = () => {
    const formData = {
      template_name: templateName,
      template_code: templateCode,
      fees_items: feesItems,
    };

    axios
      .put(
        `${Constants.api_url}/feestemplate/update-template/${templateCode}`,
        formData
      )
      .then(function (result) {
        console.log(result.data);
        swal({
          title: "Success",
          text: "Successfully updated",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setFeesItems([{ items: "", amount: "" }]);
            setTemplateName("");
            setTemplateCode("");
            handleeditClose();
            getDataGridData();
          }
        });
      })
      .catch(function (error) {
        swal({
          title: "Warning!",
          text: "Please enter valid details",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };
  return (
    <div className={FD.maincontainer}>
      <div className={FD.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={FD.backbutton} />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "80vh",
              overflowY: "scroll",
            }}
          >
            <label>
              Template Name:
              <input
                type="text"
                className={FD.inputtext}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </label>
            <br />
            <label>
              Template Code:
              <input
                type="text"
                className={FD.inputtext}
                value={templateCode}
                onChange={(e) => setTemplateCode(e.target.value)}
              />
            </label>
            <br />
            <label>Fees Items:</label>
            {feesItems.map((feesItem, index) => (
              <div key={index} className={FD.feesitemblock}>
                <input
                  type="text"
                  placeholder="Name"
                  className={FD.inputfeesitem}
                  value={feesItem.item_name}
                  onChange={(e) =>
                    handleFeesItemChange(index, "item_name", e.target.value)
                  }
                />
                <input
                  type="number"
                  placeholder="Amount"
                  className={FD.inputfeesitem}
                  value={feesItem.amount}
                  onChange={(e) =>
                    handleFeesItemChange(index, "amount", e.target.value)
                  }
                />{" "}
                <button onClick={() => deleteFeesItem(index)}>
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <button onClick={addFeesItem} className={FD.addbutton}>
              Add Another Fees Item
            </button>

            <br />
            <button onClick={handleFormSubmit} className={FD.savebutton}>
              Submit
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editopen}
        onClose={handleeditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "80vh",
              overflowY: "scroll",
            }}
          >
            <label>
              Template Name:
              <input
                type="text"
                className={FD.inputtext}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </label>
            <br />

            <label>Fees Items:</label>
            {feesItems.map((feesItem, index) => (
              <div key={index} className={FD.feesitemblock}>
                <input
                  type="text"
                  placeholder="Name"
                  className={FD.inputfeesitem}
                  value={feesItem.item_name}
                  onChange={(e) =>
                    handleFeesItemChange(index, "item_name", e.target.value)
                  }
                />
                <input
                  type="number"
                  placeholder="Amount"
                  className={FD.inputfeesitem}
                  value={feesItem.amount}
                  onChange={(e) =>
                    handleFeesItemChange(index, "amount", e.target.value)
                  }
                />{" "}
                <button onClick={() => deleteFeesItem(index)}>
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <button onClick={addFeesItem} className={FD.addbutton}>
              Add Fees Item
            </button>

            <br />
            <button onClick={handleUpdate} className={FD.savebutton}>
              Update
            </button>
            <button onClick={handleDelete} className={FD.deletebutton}>
              Delete
            </button>
          </div>
        </Box>
      </Modal>
      <div className={FD.headingholder}>
        <div className={FD.lefthead}>
          <img
            src={feesIcon}
            alt="fees template"
            className={FD.noticeboardicon}
          />
          <h3 className={FD.heading}>Fees Templates</h3>
        </div>
      </div>
      {spin === false ? (
        <div className={FD.TemplateCardsDiv}>
          <div className={FD.addstudentcard} onClick={handleOpen}>
            <PlusIcon />
            <h3 className={FD.heading}> Create New Template</h3>
          </div>
          {templates.length > 0 &&
            templates.map((item, index) => (
              <div
                className={FD.addstudentcard}
                onClick={() => handleTemplate(item)}
                key={index}
              >
                <h3 className={FD.heading}>{item.template_name}</h3>
              </div>
            ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="100%" height={500} />
        </div>
      )}
    </div>
  );
}

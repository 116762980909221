// Dependencies imports
import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";

// Local imports
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

// Style imports
import Ad from "./addstudent.module.scss";
import Ed from "./editStudentModal.module.scss";

const useStyles = (theme) => {
  return {
    backdrop: styled("div")({
      zIndex: theme?.zIndex?.drawer + 1,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const EditStudenModal = (props) => {
  const { studentDetails, handleEditStudentModalClose, getDataGridData } =
    props;
  let userDetails = useSelector(authData);
  console.log("studentDetails", studentDetails);
  const [studentEmail, setStudentEmail] = useState(studentDetails.email);
  const [studentName, setStudentName] = useState(studentDetails.name);
  const [contact, setContact] = useState(studentDetails.phone.toString());
  const [level, setLevel] = useState(studentDetails.level);
  const [isActive, setIsActive] = useState(studentDetails.is_active);
  const [password, setPassword] = useState("");
  const [reEnterPass, setReEnterPass] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [reEnterPassErr, setReEnterPassErr] = useState("");
  const [studentNameErr, setStudentNameErr] = useState("");
  const [contactErr, setContactErr] = useState("");
  const [levelErr, setLevelErr] = useState("");
  const [studentEmailErr, setStudentEmailErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setStudentNameErr("");
    setStudentEmailErr("");
    setContactErr("");
    setLevelErr("");

    if (
      studentName === "" ||
      studentName === null ||
      studentName.trim() === ""
    ) {
      formIsValid = false;
      setStudentNameErr("Please enter student's name");
      return;
    }

    if (
      studentEmail === "" ||
      studentEmail === null ||
      studentEmail.trim() === ""
    ) {
      formIsValid = false;
      setStudentEmailErr("Email cannot be empty");
    }

    if (contact === "" || contact === null || contact.trim() === "") {
      formIsValid = false;
      setContactErr("Please enter Parent contact number");
      return;
    }
    if (
      level === "" ||
      level === null ||
      level.trim() === "" ||
      level === "select level"
    ) {
      formIsValid = false;
      setLevelErr("Please select class");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      editStudent();
      return;
    }
  };

  // Function to validate login form
  const validateResetForm = () => {
    let formIsValid = true;
    setPasswordErr("");
    setReEnterPassErr("");

    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPasswordErr("Password Cannot be empty");
      return;
    }
    if (
      reEnterPass === "" ||
      reEnterPass === null ||
      reEnterPass.trim() === ""
    ) {
      formIsValid = false;
      setReEnterPassErr("Please Re-enter the password");
      return;
    }
    if (reEnterPass !== password) {
      formIsValid = false;
      setReEnterPassErr("Password is not matching");
      return;
    }
    if (formIsValid === true) {
      updatePassword();
      return;
    }
  };

  const updatePassword = () => {
    setSpin(true);

    let formdata = new FormData();
    formdata.append("email", studentEmail);
    formdata.append("password", password);
    formdata.append("re_password", reEnterPass);

    axios
      .post(`${Constants.api_url}/auth/password/set`, formdata)
      .then((response) => {
        console.log("result", response.data);
        setSpin(false);
        if (response.data.code === 207) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              handleEditStudentModalClose();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  //API to issue kits
  const editStudent = () => {
    setSpin(true);
    var data = JSON.stringify({
      name: studentName.toString(),
      email: studentEmail.toString(),
      phone: contact.toString(),
      level: level.toString(),
    });

    var config = {
      method: "put",
      url: `${Constants.api_url}/users/update/${userDetails.school_code}/${studentEmail}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        if (result.data.code === 200) {
          setSpin(false);
          swal({
            title: "Success",
            text: result.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setStudentName("");
              setStudentEmail("");
              setContact("");
              setLevel("");
              getDataGridData();
              handleEditStudentModalClose();
            }
          });
        } else {
          setSpin(false);
          swal({
            title: "Oops",
            text: result.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch(function (error) {
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={Ed.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Ed.topbar}>
        <Close
          className={Ad.backbutton}
          onClick={handleEditStudentModalClose}
        />
      </div>
      <div className={Ad.centerformholder}>
        <div className={Ad.inputblock}>
          <label for="studentname" className={Ad.formlabel}>
            Student Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="studentname"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />
          <h4 className={Ad.errormessage}>{studentNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="studentemail" className={Ad.formlabel}>
            Student Email
          </label>
          <input
            type="email"
            className={Ad.inputtext}
            id="studentemail"
            value={studentEmail}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          />
          <h4 className={Ad.errormessage}>{studentEmailErr}</h4>
        </div>

        <div className={Ad.inputblock}>
          <label for="parentphonenumber" className={Ad.formlabel}>
            Parent Phone Number
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="parentphonenumber"
            onChange={(e) => setContact(e.target.value)}
            value={contact}
          />
          <h4 className={Ad.errormessage}>{contactErr}</h4>
        </div>
        {/* <div className={Ad.inputblock}>
          <label for="parentphonenumber" className={Ad.formlabel}>
            Parent Phone Number
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="parentphonenumber"
            onChange={(e) => setContact(e.target.value)}
            value={contact}
          />
          <h4 className={Ad.errormessage}>{contactErr}</h4>
        </div> */}
        <div className={Ad.inputblock}>
          <label className={Ad.formlabel}>Student Class</label>
          <select
            className={Ad.selectblock}
            value={level}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          >
            <option selected>Select Class</option>
            <option value="playgroup">Playgroup</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
          </select>
          <h4 className={Ad.errormessage}>{levelErr}</h4>
        </div>
        <div className={Ad.savebuttonholder}>
          {isActive === true ? (
            <button
              className={Ad.savebutton}
              onClick={() => {
                validateForm();
              }}
            >
              Save Changes
            </button>
          ) : (
            <button
              className={Ad.savebutton}
              style={{
                opacity: 0.6,
                cursor: "not-allowed",
              }}
              onClick={() => {}}
            >
              Save Changes
            </button>
          )}
        </div>
      </div>
      <div className={Ad.resetformholder}>
        <div className={Ad.inputblock}>
          <label for="email" className={Ad.formlabel}>
            Reset Password
          </label>
          <input
            type="password"
            className={Ad.inputtext}
            id="email"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <h4 className={Ad.errormessage}>{passwordErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="reenterpassword" className={Ad.formlabel}>
            Re-enter Password
          </label>
          <input
            type="password"
            className={Ad.inputtext}
            id="reenterpassword"
            onChange={(e) => setReEnterPass(e.target.value)}
            value={reEnterPass}
          />
          <h4 className={Ad.errormessage}>{reEnterPassErr}</h4>
        </div>
        <div className={Ad.savebuttonholder}>
          {isActive === true ? (
            <button
              className={Ad.savebutton}
              onClick={() => {
                validateResetForm();
              }}
            >
              Save Password
            </button>
          ) : (
            <button
              className={Ad.savebutton}
              style={{ opacity: 0.6, cursor: "not-allowed" }}
              onClick={() => {}}
            >
              Save Password
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditStudenModal;

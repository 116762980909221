import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import qs from "qs";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import AddStudentImage from "../../assets/icons/addstudenticon.png";
import { Constants } from "../../constants";
import Ad from "./addstudent.module.scss";
import SaveConfirmationModal from "./saveConfirmationModal";
import { Modal } from "@mui/material";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const ModalContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
});

const AddStudent = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.value);
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [studentEmail, setStudentEmail] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState("");
  const [discount, setDiscount] = useState("");
  const [contact, setContact] = useState("");
  const [level, setLevel] = useState("select level");
  const [studentNameErr, setStudentNameErr] = useState("");
  const [contactErr, setContactErr] = useState("");
  const [levelErr, setLevelErr] = useState("");
  const [studentEmailErr, setStudentEmailErr] = useState("");
  const [templates, setTemplates] = useState([]);
  const [feesItems, setFeesItems] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [totalFees, setTotalFees] = useState(0);
  const [totalFeesBeforeDiscount, setTotalFeesBeforeDiscount] = useState(0);
  const [totalFeesafterdiscount, settotalFeesafterdiscount] = useState(0);
  const [error, setError] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [sumOfInstallments, setSumOfInstallments] = useState(0);

  const handleConfirmationModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleConfirmationModalClose = () => {
    setOpenDeleteModal(false);
  };

  const validateForm = () => {
    let formIsValid = true;
    setStudentNameErr("");
    setStudentEmailErr("");
    setContactErr("");
    setLevelErr("");
    setError("");

    if (!studentName.trim()) {
      formIsValid = false;
      setStudentNameErr("Please enter student's name");
    }

    if (!studentEmail.trim()) {
      formIsValid = false;
      setStudentEmailErr("Email cannot be empty");
    }

    if (!contact.trim()) {
      formIsValid = false;
      setContactErr("Please enter Parent contact number");
    }

    if (level === "select level") {
      formIsValid = false;
      setLevelErr("Please select class");
    }

    if (!studentId.trim()) {
      setError("Please add Student Id.");
    }

    if (feesItems.length === 0) {
      formIsValid = false;
      setError("Please add at least one installment");
    }

    // Check if any date input is in the past
    // for (const feeItem of feesItems) {
    //   if (new Date(feeItem.due_date) < new Date()) {
    //     formIsValid = false;
    //     setError("Installment date is invalid");
    //     break;
    //   }
    // }

    let totalInstallmentsAmount = 0;
    for (const feeItem of feesItems) {
      totalInstallmentsAmount += parseFloat(feeItem.amount);
    }
    if (totalInstallmentsAmount > totalFees) {
      formIsValid = false;
      setError("Total amount of installments exceeds total fees");
    }

    if (totalFeesafterdiscount !== totalInstallmentsAmount) {
      formIsValid = false;
      setError(
        "Total fees should match the sum of installment amounts and the discount"
      );
    }
    if (formIsValid) {
      setStudentData(
        qs.stringify({
          name: studentName,
          email: studentEmail,
          phone: contact,
          school_code: userDetails.school_code,
          level: level,
          discount: discount,
          student_id: studentId,
          total_fees_before_discount: totalFeesBeforeDiscount,
          total_fees_after_discount: totalFeesafterdiscount,
          fees_items: JSON.stringify(feesItems), // Serialize fees_items array
        })
      );
      handleConfirmationModalOpen();
    }
  };

  // const addStudent = () => {
  //   setSpin(true);
  // const data = qs.stringify({
  //   name: studentName,
  //   email: studentEmail,
  //   phone: contact,
  //   school_code: userDetails.school_code,
  //   level: level,
  //   discount: discount,
  //   student_id: studentId,
  //   total_fees_before_discount: totalFeesBeforeDiscount,
  //   total_fees_after_discount: totalFeesafterdiscount,
  //   fees_items: JSON.stringify(feesItems), // Serialize fees_items array
  // });

  //   const config = {
  //     method: "POST",
  //     url: `${Constants.api_url}/users/initial/student`,
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (result) {
  //       setSpin(false);
  //       if (result.data.code === 200) {
  //         swal({
  //           title: "Success",
  //           text: "User added successfully",
  //           icon: "success",
  //           dangerMode: false,
  //           closeOnClickOutside: false,
  //         }).then(() => {
  //           setStudentName("");
  //           setStudentEmail("");
  //           setContact("");
  //           setLevel("");
  //           setSelectedTemplate("");
  //           setFeesItems([]);
  //           setDiscount("");
  //           settotalFeesafterdiscount(0);
  //           setTotalFeesBeforeDiscount(0);
  //           setSelectedTemplate("");
  //           setStudentId("");
  //         });
  //       } else {
  //         swal({
  //           title: "Warning!",
  //           text: "User with email already exists",
  //           icon: "warning",
  //           dangerMode: false,
  //           closeOnClickOutside: false,
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setSpin(false);
  //       swal({
  //         title: "Oops!",
  //         text: "Something went wrong",
  //         icon: "warning",
  //         dangerMode: false,
  //         closeOnClickOutside: false,
  //       });
  //     });
  // };

  const handleFeesItemChange = (index, key, value) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems[index][key] = value;
    setFeesItems(updatedFeesItems);
    console.log(feesItems);
    setSumOfInstallments(
      feesItems.reduce((acc, feesItem) => acc + Number(feesItem.amount), 0)
    );
  };

  const addFeesItem = () => {
    setFeesItems([
      ...feesItems,
      { due_date: "", amount: "", installment_name: "" },
    ]);
  };

  const deleteFeesItem = (index) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems.splice(index, 1);
    setFeesItems(updatedFeesItems);
    setSumOfInstallments(
      updatedFeesItems.reduce(
        (acc, feesItem) => acc + Number(feesItem.amount),
        0
      )
    );
  };

  useEffect(() => {
    if (userDetails.school_code) {
      getDataGridData();
    }
  }, [userDetails.school_code]);

  const getDataGridData = () => {
    setSpin(true);

    const config = {
      method: "GET",
      url: `${Constants.api_url}/feestemplate/?school_code=${userDetails.school_code}`,
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        setTemplates(result.data);
      })
      .catch(function (error) {
        setSpin(false);
        console.log(error.message);
      });
  };

  const handleDiscountChange = (e) => {
    const discountValue =
      e.target.value === "" ? "" : parseFloat(e.target.value);

    setDiscount(discountValue);

    if (discountValue !== "" && !isNaN(discountValue)) {
      const discountedAmount = totalFees - (totalFees * discountValue) / 100;
      settotalFeesafterdiscount(discountedAmount >= 0 ? discountedAmount : "");
    } else {
      settotalFeesafterdiscount(totalFees);
    }
  };

  const handleChangeFeesTemplate = (event) => {
    const selectedTemplateName = event.target.value;
    const selectedTemplate = templates.find(
      (template) => template.template_name === selectedTemplateName
    );
    if (selectedTemplate) {
      setTotalFees(selectedTemplate.total_fees);
      setTotalFeesBeforeDiscount(selectedTemplate.total_fees);
      settotalFeesafterdiscount(selectedTemplate.total_fees);
      setSelectedTemplate(selectedTemplate);
    }
  };

  return (
    <div className={Ad.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Ad.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Ad.backbutton} />
      </div>
      <div className={Ad.headingholder}>
        <img
          className={Ad.addstudenticon}
          src={AddStudentImage}
          alt="addstudenticon"
        />
        <h3 className={Ad.heading}>Add a Student</h3>
      </div>
      <div className={Ad.centerformholder}>
        <div className={Ad.inputblock}>
          <label for="studentId" className={Ad.formlabel}>
            Student Id
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="studentId"
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
            required
          />
        </div>
        <div className={Ad.inputblock} style={{ marginTop: "20px" }}>
          <label for="studentname" className={Ad.formlabel}>
            Student Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="studentname"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />
          <h4 className={Ad.errormessage}>{studentNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="studentemail" className={Ad.formlabel}>
            Student Email
          </label>
          <input
            type="email"
            className={Ad.inputtext}
            id="studentemail"
            value={studentEmail}
            onChange={(e) => setStudentEmail(e.target.value)}
          />
          <h4 className={Ad.errormessage}>{studentEmailErr}</h4>
        </div>

        <div className={Ad.inputblock}>
          <label for="parentphonenumber" className={Ad.formlabel}>
            Parent Phone Number
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="parentphonenumber"
            onChange={(e) => setContact(e.target.value)}
            value={contact}
          />
          <h4 className={Ad.errormessage}>{contactErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label className={Ad.formlabel}>Student Class</label>
          <select
            className={Ad.selectblock}
            onChange={(event) => {
              setLevel(event.target.value);
            }}
            value={level}
          >
            <option>Select Class</option>
            <option value="playgroup">Playgroup</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
          </select>
          <h4 className={Ad.errormessage}>{levelErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label className={Ad.formlabel}>Fee Structure</label>
          <select
            className={Ad.selectblock}
            onChange={(event) => {
              handleChangeFeesTemplate(event);
            }}
            value={selectedTemplate.template_name}
          >
            <option>Select Fees</option>
            {templates.map((template) => (
              <option
                key={template.template_name}
                value={template.template_name}
              >
                {template.template_name}
              </option>
            ))}
          </select>
          <h4 className={Ad.errormessage}>{levelErr}</h4>
        </div>

        <div className={Ad.inputblock} style={{ marginBlock: "10px" }}>
          <label htmlFor="discount" className={Ad.formlabel}>
            Discount %
          </label>
          <input
            type="number"
            className={Ad.inputtext}
            id="discount"
            value={discount}
            onChange={handleDiscountChange}
            placeholder="Enter discount percentage"
          />
        </div>

        <label className={Ad.formlabel} style={{ alignSelf: "start" }}>
          Installments
        </label>
        {feesItems.map((feesItem, index) => (
          <div key={index} className={Ad.feesitemblock}>
            <div className={Ad.inputblock} style={{ width: "fitcontent" }}>
              <input
                type="date"
                className={Ad.inputtext}
                value={feesItem.due_date}
                onChange={(e) =>
                  handleFeesItemChange(index, "due_date", e.target.value)
                }
                style={{
                  width: "100%",
                  height: "50px",

                  fontWeight: "800",
                  fontSize: "20px",
                  paddingLeft: "10px",
                }}
              />
              <h4 className={Ad.errormessage}>{levelErr}</h4>
            </div>
            <input
              type="number"
              placeholder="Amount"
              className={Ad.inputfeesitem}
              value={feesItem.amount}
              onChange={(e) =>
                handleFeesItemChange(index, "amount", e.target.value)
              }
              style={{
                width: "100%",
                height: "50px",
                fontWeight: "800",
                fontSize: "20px",
                paddingLeft: "10px",
              }}
            />
            <input
              type="text"
              placeholder="Remarks"
              className={Ad.inputtext}
              value={feesItem.installmentName}
              onChange={(e) =>
                handleFeesItemChange(index, "installment_name", e.target.value)
              }
              style={{
                width: "100%",
                height: "50px",
                fontWeight: "800",
                fontSize: "20px",
                paddingLeft: "10px",
              }}
            />
            <button
              onClick={() => deleteFeesItem(index)}
              style={{ height: "50px", width: "40%", cursor: "pointer" }}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
        {feesItems.length > 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p className={Ad.formlabel}>
              Installments Total Amount = {sumOfInstallments}
            </p>
          </div>
        )}
        <button onClick={addFeesItem} className={Ad.addbutton}>
          Add Installments
        </button>

        {selectedTemplate && (
          <>
            <div className={Ad.inputblock} style={{ marginTop: "20px" }}>
              <label className={Ad.discountlabel}>
                Fees before Discount: <span>{totalFeesBeforeDiscount}</span>
              </label>
            </div>
            <div className={Ad.inputblock}>
              <label className={Ad.discountlabel}>
                Fees after Discount:{" "}
                <span style={{ fontWeight: "800" }}>
                  {totalFeesafterdiscount}
                </span>
              </label>
            </div>
          </>
        )}
        <div className={Ad.error} style={{ color: "red" }}>
          <p>{error}</p>
        </div>
        <div className={Ad.savebuttonholder}>
          <button
            className={Ad.savebutton}
            onClick={() => {
              validateForm();
            }}
          >
            Save
          </button>
        </div>
      </div>

      <ModalContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDeleteModal}
          onClose={handleConfirmationModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={openDeleteModal}> */}
          <SaveConfirmationModal
            handleConfirmationModalClose={handleConfirmationModalClose}
            data={studentData}
            setStudentName={setStudentName}
            setStudentEmail={setStudentEmail}
            setContact={setContact}
            setLevel={setLevel}
            setSelectedTemplate={setSelectedTemplate}
            setFeesItems={setFeesItems}
            setDiscount={setDiscount}
            settotalFeesafterdiscount={settotalFeesafterdiscount}
            setTotalFeesBeforeDiscount={setTotalFeesBeforeDiscount}
            setStudentId={setStudentId}
          />
          {/* </Fade> */}
        </Modal>
      </ModalContainer>
    </div>
  );
};

export default AddStudent;

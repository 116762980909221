import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";

import Lo from "./logoutdiv.module.scss";

const Logout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setShowLogout } = props;

  function logout() {
    dispatch(signOut());
    navigate.push("/");
  }

  return (
    <div
      className={Lo.maincontainer}
      onClick={() => logout()}
      onMouseLeave={() => setShowLogout(false)}
    >
      Logout
    </div>
  );
};

export default Logout;

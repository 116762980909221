import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import axios from "axios";
import swal from "sweetalert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../assets/logo/logo.png";
import { ReactComponent as Avatar } from "../../assets/icons/avatar.svg";
import { ReactComponent as Bell } from "../../assets/icons/bell.svg";
import AddStudentIcon from "../../assets/icons/addstudenticon.png";
import MessageIcon from "../../assets/icons/messageicon.png";
import NoticeBoardIcon from "../../assets/icons/noticeboardicon.png";
import LibraryIcon from "../../assets/icons/libraryicon.png";
import Logout from "../widgets/logoutdiv";
import { Constants } from "../../constants";
import AddSchoolIcon from "../../assets/icons/add-person.svg";
import AddSchoolDirectoryIcon from "../../assets/icons/directory.svg";
import FeesIcon from "../../assets/icons/fees.svg";
import Dash from "./dashboard.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [spin, setSpin] = useState(true);
  const userDetails = useSelector((state) => state.auth.value);
  const classes = useStyles();
  const [finalUrl, setFinalUrl] = useState("");
  const [showLogout, setShowLogout] = useState(false);

  // Useeffect for api call
  useEffect(() => {
    getMesiboToken();

    //eslint-disable-next-line
  }, []);

  //API to get product details
  const getMesiboToken = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/users/mesibo_token/${userDetails.email}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        let urlStri = `email=${userDetails.email}&accessToken=${result.data.mesibo_user_token}`;
        let finalUrlforNow = `https://brightbeepchat.brightedumont.com/messenger.html?${urlStri}`;
        setFinalUrl(finalUrlforNow);
        console.log("finalurl", finalUrlforNow);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={Dash.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      {showLogout === true ? <Logout setShowLogout={setShowLogout} /> : null}
      {userDetails.role !== "superadmin" ? (
        <>
          <div className={Dash.topbar}>
            <div className={Dash.leftbar}>
              <img src={Logo} className={Dash.logo} alt="brightbeeplogo" />
              <div className={Dash.detailholder}>
                <h2 className={Dash.dashheading}>Dashboard !</h2>
                <h3 className={Dash.schoolname}>
                  Welcome {userDetails.school_name}
                </h3>
                <h3 className={Dash.schoolcode}>
                  School Code: {userDetails.school_code}
                </h3>
              </div>
            </div>
            <div className={Dash.rightbar}>
              <Bell className={Dash.icons} />
              <Avatar
                className={Dash.icons}
                onClick={() => setShowLogout(true)}
              />
            </div>
          </div>
          <div className={Dash.middlecardsholder}>
            <div
              className={Dash.librarycard}
              onClick={() => navigate("/montlibrary")}
            >
              <img src={LibraryIcon} alt="libraryicon" className={Dash.icon} />
              <h4 className={Dash.bottomcardtext}>Mont.Library</h4>
            </div>
            <a href={finalUrl} target="_blank" rel="noreferrer">
              <div
                className={Dash.messagecard}
                // onClick={() => navigate("/messages")}
              >
                <img
                  src={MessageIcon}
                  alt="messageicon"
                  className={Dash.icon}
                />
                <h4 className={Dash.bottomcardtext}>Messages</h4>
              </div>
            </a>
            <div
              className={Dash.noticeboardcard}
              onClick={() => navigate("/noticeboard")}
            >
              <img
                src={NoticeBoardIcon}
                alt="noticeboardicon"
                className={Dash.icon}
              />
              <h4 className={Dash.bottomcardtext}>Notice Board</h4>
            </div>
            <div
              className={Dash.addstudentcard}
              onClick={() => navigate("/addstudent")}
            >
              <img
                src={AddStudentIcon}
                alt="addstudenticon"
                className={Dash.icon}
              />
              <h4 className={Dash.bottomcardtext}>Add Student</h4>
            </div>
            <div
              className={Dash.addstudentcard}
              onClick={() => navigate("/userdirectory")}
            >
              <img
                src={AddSchoolDirectoryIcon}
                alt="addstudenticon"
                className={Dash.icon}
              />
              <h4 className={Dash.bottomcardtext}>User Directory</h4>
            </div>{" "}
            <div
              className={Dash.addstudentcard}
              onClick={() => navigate("/feesdirectory")}
            >
              <img
                src={FeesIcon}
                alt="Fees Management"
                className={Dash.icon}
                style={{width:"100px"}}
              />
              <h4 className={Dash.bottomcardtext}>Fees Management</h4>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={Dash.topbar}>
            <div className={Dash.leftbar}>
              <img src={Logo} className={Dash.logo} alt="brightbeeplogo" />
              <div className={Dash.detailholder}>
                <h2 className={Dash.dashheading}>Dashboard !</h2>
                <h3 className={Dash.schoolname}>{userDetails.school_name}</h3>
              </div>
            </div>
            <div className={Dash.rightbar}>
              <Bell className={Dash.icons} />
              <Avatar
                className={Dash.icons}
                onClick={() => setShowLogout(true)}
              />
            </div>
          </div>
          <div className={Dash.middlecardsholder}>
            <div
              className={Dash.librarycard}
              onClick={() => navigate("/addschool")}
            >
              <img
                src={AddSchoolIcon}
                alt="libraryicon"
                className={Dash.icon}
              />
              <h4 className={Dash.bottomcardtext}>Add School</h4>
            </div>
            <div
              className={Dash.addstudentcard}
              onClick={() => navigate("/activateschool")}
            >
              <img
                src={AddStudentIcon}
                alt="libraryicon"
                className={Dash.icon}
              />
              <h4 className={Dash.bottomcardtext}>
                Activate/Deactivate School
              </h4>
            </div>
            <div
              className={Dash.addstudentcard}
              onClick={() => navigate("/schooldirectory")}
            >
              <img
                src={AddSchoolDirectoryIcon}
                alt="libraryicon"
                className={Dash.icon}
              />
              <h4 className={Dash.bottomcardtext}>School Directory</h4>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;

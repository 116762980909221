// Dependencies imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import NoticeBoardIcon from "../../assets/icons/noticeboardicon.png";

// Style imports
import No from "./individualNotice.module.scss";

const IndividualNotice = () => {
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.value);

  return (
    <div className={No.maincontainer}>
      <div className={No.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={No.backbutton} />
      </div>
      <div className={No.headingholder}>
        <img
          src={NoticeBoardIcon}
          alt="noticeboardicon"
          className={No.noticeboardicon}
        />
        <h3 className={No.heading}>Notice Board</h3>
      </div>
      <div className={No.rightside}>
        <h4 className={No.topichead}>{userDetails.notice_title}</h4>
        <img
          className={No.topicimage}
          alt="topicimage"
          src={userDetails.attachment}
        />
        <p className={No.noticedescription}>{userDetails.notice_body}</p>
      </div>
    </div>
  );
};

export default IndividualNotice;

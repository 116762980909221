// import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
// import { combineReducers } from "redux";
// import {
//   persistReducer,

// } from "redux-persist";
// import authReducer from "../auth/authSlice";
// import loginReducer from "../auth/loginSlice";

// const reducers = combineReducers({
//   auth: authReducer,
//   login: loginReducer,
// });

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducers);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   }),
//   devTools: process.env.NODE_ENV !== 'production',
//   middleware: [thunk]
// });

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../auth/authSlice";
import loginReducer from "../auth/loginSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    login: loginReducer,
  },
});

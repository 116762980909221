import React, { useState, useEffect } from "react";
import FD from "./feesdirectory.module.scss";
import feesIcon from "../../assets/icons/fees.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/icons/addicon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { DataGrid } from "@mui/x-data-grid";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants";
import qs from "qs";
import swal from "sweetalert";
import Ad from "./addstudent.module.scss";
import { ReactComponent as EditUser } from "../../assets/icons/edituser.svg";
import { ReactComponent as QRCode } from "../../assets/icons/qrcode.svg";
import { ReactComponent as EmailReminder } from "../../assets/icons/send-email.svg";
import { ReactComponent as ActivateUser } from "../../assets/icons/activateuser.svg";
import { ReactComponent as DeactivateUser } from "../../assets/icons/deactivateuser.svg";
import moment from "moment";

import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import EditStudentModal from "./editStudentFeesModal";
const ModalContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
});
const BackdropContainer = styled("div")(({ theme }) => ({
  zIndex: theme?.zIndex?.drawer + 1,
  color: "#fff",
}));

export default function Feesdirectory() {
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.value);
  const [spin, setSpin] = useState(true);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [studentDetails, setStudentDetails] = useState();
  const [totalDueAmount, setTotalDueAmount] = useState(0);

  const [openEditModal, setOpenEditModal] = useState(false);
  const handleEditStudentModalOpen = () => {
    setOpenEditModal(true);
  };

  const handleEditStudentModalClose = () => {
    setOpenEditModal(false);
  };

  const DataGridContainer = styled("div")({
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    background: "whitesmoke",
  });
  useEffect(() => {
    getDataGridData();
    //eslint-disable-next-line
  }, []);
  const getDataGridData = () => {
    setSpin(true);
    var data = qs.stringify({});

    var config = {
      method: "GET",
      url: `${Constants.api_url}/users/students/directory/?school_code=${userDetails.school_code}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log("school directory", result.data);
        tableViewConvertion(result.data);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const sendReminderEmail = (stuDetails) => {
    setSpin(true);
    let formdata = new FormData();
    formdata.append("student_email", stuDetails.email);
    formdata.append("school_code", userDetails.school_code);

    axios
      .post(`${Constants.api_url}/fees_installments/reminder_email`, formdata)
      .then((response) => {
        setSpin(false);
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              handleEditStudentModalClose();
              getDataGridData();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      });
  };

  const tableViewConvertion = (value) => {
    let trows = [];
    let totalDueAmount = 0;
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.name = value[i];
      obj.email = value[i]["email"];
      obj.phone = value[i]["phone"];
      obj.class = value[i]["level"];

      obj.installmentDue = 0;
      obj.installmentDueDate = "NA";
      obj.totalPaidAmount = 0;
      obj.agreedFees = "NA";

      if (value[i]["fees_details"] !== null) {
        var installments = value[i]["fees_details"]["installments"];
        var feesPaid =
          value[i]["fees_payments"] !== null
            ? value[i]["fees_payments"]["payments"]
            : 0;
        var totalFeesPaidAmount =
          feesPaid !== 0
            ? feesPaid.reduce((acc, feesPaidItem) => {
                return acc + feesPaidItem.paid_amount;
              }, 0)
            : 0;
        var installmentAmount = 0;
        var agreedFees = 0;
        var currentDate = new Date();
        for (var j = 0; j < installments.length; j++) {
          agreedFees += installments[j]["amount"];
          if (new Date(installments[j]["due_date"]) <= currentDate) {
            installmentAmount += installments[j]["amount"];

            obj.installmentDueDate = installments[j]["due_date"];
          }
        }

        obj.agreedFees = agreedFees;

        obj.installmentDue =
          installmentAmount - totalFeesPaidAmount >= 0
            ? installmentAmount - totalFeesPaidAmount
            : 0;

        totalDueAmount += obj.installmentDue;

        obj.totalPaidAmount = totalFeesPaidAmount;
      }

      obj.feesPaidItems = value[i]["fees_payments"];

      obj.kitname =
        value[i]["pending_montlib_kits"] !== null
          ? value[i]["pending_montlib_kits"]["kit_name"]
          : "No Pending";
      obj.duedate =
        value[i]["pending_montlib_kits"] !== null
          ? moment(value[i]["pending_montlib_kits"]["due_date"]).format(
              "Do MMM YYYY"
            )
          : "--/--/----";

      obj.activate = value[i].is_active;
      obj.actions = value[i];

      trows.push(obj);
    }
    setTotalDueAmount(totalDueAmount);

    const tcolumns = [
      {
        field: "name",
        headerName: "Name",
        flex: 0.8,
        headerClassName: "super-app-theme--header",
        renderCell: (param) => {
          return (
            <h3
              style={{ color: param.row.name["is_active"] ? "green" : "red" }}
            >
              {param.row.name.name}
            </h3>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1.2,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "class",
        headerName: "Class",
        flex: 0.7,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "installmentDue",
        headerName: "Current Due",
        flex: 0.8,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "installmentDueDate",
        headerName: "Fees Due Date",
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "totalPaidAmount",
        headerName: "Total Paid",
        flex: 0.8,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "agreedFees",
        headerName: "Agreed Fees",
        flex: 0.8,
        headerClassName: "super-app-theme--header",
      },
      // {
      //   field: "activate",
      //   headerName: "Access",
      //   flex: 0.4,
      //   headerClassName: "super-app-theme--header",
      //   renderCell: (param) => {
      //     console.log("whats inside", param);
      //     return param.value ? (
      //       <DeactivateUser
      //         onClick={() => handleCellClick(param)}
      //         className={Ad.actionicons}
      //       />
      //     ) : (
      //       <ActivateUser
      //         onClick={() => handleCellClick(param)}
      //         className={Ad.actionicons}
      //       />
      //     );
      //   },
      // },
      {
        field: "actions",
        headerName: "Actions",
        flex: 0.5,
        headerClassName: "super-app-theme--header",
        renderCell: (param) => {
          console.log("whats inside", param);
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1vw",
              }}
            >
              <EditUser
                className={Ad.actionicons}
                onClick={() => {
                  setStudentDetails(param.value);
                  handleEditStudentModalOpen();
                }}
              />
              <EmailReminder
                className={Ad.actionicons}
                onClick={() => {
                  setStudentDetails(param.value);
                  sendReminderEmail(param.value);
                }}
              />
            </div>
          );
        },
      },
      // {
      //   field: "reminder",
      //   headerName: "Reminder",
      //   flex: 0.5,
      //   headerClassName: "super-app-theme--header",
      //   renderCell: (param) => {
      //     console.log("what is in param: ", param);
      //     return (
      //       <div
      //         style={{
      //           display: "flex",
      //           alignItems: "center",
      //           justifyContent: "center",
      //           gap: "1vw",
      //         }}
      //       >
      //         <EmailReminder
      //           className={Ad.actionicons}
      //           onClick={() => {
      //             setStudentDetails(param.value);
      //             sendReminderEmail();
      //           }}
      //         />
      //       </div>
      //     );
      //   },
      // },
    ];

    setRows(trows);
    setColumns(tcolumns);
    setSpin(false);
  };
  return (
    <div className={FD.maincontainer}>
      <ModalContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openEditModal}
          onClose={handleEditStudentModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            invisible: true,
          }}
        >
          {/* <Fade in={openEditModal}> */}
          <EditStudentModal
            handleEditStudentModalClose={handleEditStudentModalClose}
            studentDetails={studentDetails}
            getDataGridData={getDataGridData}
          />
          {/* </Fade> */}
        </Modal>
      </ModalContainer>
      <div className={FD.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={FD.backbutton} />
      </div>
      <div className={FD.headingholder}>
        <div className={FD.lefthead}>
          <img
            src={feesIcon}
            alt="noticeboardicon"
            className={FD.noticeboardicon}
          />
          <h3 className={FD.heading}>Fees Management</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div
            className={FD.righthead}
            onClick={() => navigate("/feescollectionmethods")}
          >
            <button className={FD.addbutton}>
              <QRCode />
              Fees Collection Methods
            </button>
          </div>
          <div
            className={FD.righthead}
            onClick={() => navigate("/feestemplates")}
          >
            <button className={FD.addbutton}>
              <AddIcon />
              Manage Templates
            </button>
          </div>
        </div>
      </div>
      {spin === false ? (
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#f05e13",
              color: "white",
            },
          }}
        >
          <DataGridContainer>
            <DataGrid
              rows={rows}
              columns={columns}
              rowHeight={50}
              disableSelectionOnClick
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "#f05e13",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
            />
          </DataGridContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              Total Due Amount: &nbsp;
              <span style={{ fontWeight: "bold" }}>
                {totalDueAmount.toLocaleString()}
              </span>
            </p>
          </div>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="100%" height={500} />{" "}
        </div>
      )}
    </div>
  );
}

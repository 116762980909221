// Depedencies imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as IssuedIcon } from "../../assets/icons/issuedicon.svg";

// Style imports
import In from "./individuallog.module.scss";

const IndividualLog = () => {
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.value);

  return (
    <div className={In.maincontainer}>
      <div className={In.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={In.backbutton} />
      </div>
      <div className={In.headingholder}>
        <IssuedIcon />
        <h3 className={In.heading}>Issued Kit Log</h3>
      </div>
      <div className={In.cardholder}>
        <div
          className={
            userDetails.kit_details.return_date === null
              ? In.cardpink
              : In.cardgreen
          }
        >
          <h3 className={In.kitname}>{userDetails.kit_details.kit_name}</h3>
          <div className={In.dataholder}>
            <div className={In.valueholder}>
              <div className={In.leftlabelholder}>
                <h3 className={In.leftlabel}>Issued To :</h3>
              </div>
              <h3 className={In.rightlabel}>
                {userDetails.kit_details.student_name}
              </h3>
            </div>
            <div className={In.valueholder}>
              <div className={In.leftlabelholder}>
                <h3 className={In.leftlabel}>Class :</h3>
              </div>
              <h3 className={In.rightlabel}>
                {userDetails.kit_details.student_level}
              </h3>
            </div>
            <div className={In.valueholder}>
              <div className={In.leftlabelholder}>
                <h3 className={In.leftlabel}>Issued On :</h3>
              </div>
              <h3 className={In.rightlabel}>
                {moment(userDetails.kit_details.issue_date).format(
                  "Do MMM YYYY"
                )}
              </h3>
            </div>
            <div className={In.valueholder}>
              <div className={In.leftlabelholder}>
                <h3 className={In.leftlabel}>Returned On :</h3>
              </div>
              <h3 className={In.rightlabel}>
                {userDetails.kit_details.return_date === null
                  ? "Yet to be returned"
                  : moment(userDetails.kit_details.return_date).format(
                      "Do MMM YYYY"
                    )}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualLog;

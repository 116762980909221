// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import axios from "axios";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as IssueIcon } from "../../assets/icons/issueicon.svg";
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Style imports
import Is from "./issuekit.module.scss";
const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const IssueKit = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("Select Product");
  const [studentEmail, setStudentEmail] = useState("Select Student");
  const [studentList, setStudentList] = useState([]);
  const [studentClass, setStudentClass] = useState("Select Class");
  const [returnDate, setReturnDate] = useState("");
  const [productNameErr, setProductNameErr] = useState("");
  const [studentEmailErr, setStudentEmailErr] = useState("");
  const [studentClassErr, setStudentClassErr] = useState("");
  const [returnDateErr, setReturnDateErr] = useState("");
  const [productList, setProductList] = useState([]);
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setProductNameErr("");
    setStudentEmailErr("");
    setStudentClassErr("");
    setReturnDateErr("");
    if (
      studentClass === "" ||
      studentClass === "Select Class" ||
      studentClass === null ||
      studentClass.trim() === ""
    ) {
      formIsValid = false;
      setStudentClassErr("Please select a class");
      return;
    }
    if (
      studentEmail === "" ||
      studentEmail === "Select Student" ||
      studentEmail === null ||
      studentEmail.trim() === ""
    ) {
      formIsValid = false;
      setStudentEmailErr("Please select a student");
      return;
    }
    if (
      productName === "" ||
      productName === "Select Product" ||
      productName === null ||
      productName.trim() === ""
    ) {
      formIsValid = false;
      setProductNameErr("Please select a product");
      return;
    }
    if (returnDate === "" || returnDate === null || returnDate.trim() === "") {
      formIsValid = false;
      setReturnDateErr("Please select return date");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      issueKit();
      return;
    }
  };

  //API to get product details
  const getProducts = (event) => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/montlib/items/${event}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        setProductList(result.data);
        getStudents(event);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Somenthing went wrong!",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  //API to issue kits
  const issueKit = () => {
    setSpin(true);
    var data = qs.stringify({
      kit_name: productName.toString(),
      school_code: userDetails.school_code.toString(),
      issued_by: userDetails.email.toString(),
      student_phone: 2000000001,
      kit_code: productName.toString(),
      student_email: studentEmail.toString(),
      due_date: returnDate.toString(),
    });

    var config = {
      method: "POST",
      url: `${Constants.api_url}/montlib/issue`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        setSpin(false);
        if (result.data.code === 404) {
          swal({
            title: "Warning",
            text: "Student with email does not exists!",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        } else {
          swal({
            title: "Success",
            text: "Kit issued successfully",
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setProductName("Select Product");
              setStudentEmail("Select Student");
              setStudentClass("Select Class");
              setReturnDate("");
              setStudentList([]);
              setProductList([]);
            }
          });
        }
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  //   API to get graph and logs response
  const getStudents = (event) => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/users/students/directory/?school_code=${userDetails.school_code}&level=${event}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("student list", result.data);
        setStudentList(result.data);
        setTimeout(function () {
          //your code to be executed after 1 second
          setSpin(false);
        }, 2000);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  return (
    <div className={Is.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Is.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Is.backbutton} />
      </div>
      <div className={Is.headingholder}>
        <IssueIcon />
        <h3 className={Is.heading}>Issue a kit</h3>
      </div>
      <div className={Is.centerformholder}>
        <div className={Is.inputblock}>
          <label className={Is.formlabel}>Student Class</label>
          <select
            className={Is.selectblock}
            onChange={(event) => {
              getProducts(event.target.value);
              setStudentClass(event.target.value);
            }}
            value={studentClass}
          >
            <option selected disabled>
              Select Class
            </option>
            <option value="playgroup">Playgroup</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
          </select>
          <h4 className={Is.errormessage}>{studentClassErr}</h4>
        </div>
        <div className={Is.inputblock}>
          <label className={Is.formlabel}>Select Student</label>
          <select
            className={Is.selectblock}
            onChange={(event) => {
              setStudentEmail(event.target.value);
              console.log("email", event.target.value.email);
            }}
            value={studentEmail}
          >
            <option selected disabled>
              Select Student
            </option>
            {studentList.map((value, i) => {
              return (
                <option value={value.email} key={i}>
                  {value.name}
                </option>
              );
            })}
          </select>
          <h4 className={Is.errormessage}>{studentEmailErr}</h4>
        </div>
        <div className={Is.inputblock}>
          <label className={Is.formlabel}>Product Name</label>
          <select
            className={Is.selectblock}
            onChange={(event) => setProductName(event.target.value)}
            defaultValue="Select Product"
            value={productName}
          >
            <option selected disabled>
              Select Product
            </option>
            {productList.map((param, i) => {
              return (
                <option value={param} key={i}>
                  {param}
                </option>
              );
            })}
          </select>
          <h4 className={Is.errormessage}>{productNameErr}</h4>
        </div>
        <div className={Is.dateinputblock}>
          <div className={Is.rightinput}>
            <label className={Is.formlabel}>Return Date</label>
            <input
              type="date"
              className={Is.inputdate}
              onChange={(event) => setReturnDate(event.target.value)}
              value={returnDate}
            />
            <h4 className={Is.errormessage}>{returnDateErr}</h4>
          </div>
        </div>
        <div className={Is.savebuttonholder}>
          <button className={Is.savebutton} onClick={() => validateForm()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default IssueKit;

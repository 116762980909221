import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Dashboard from "./components/pages/dashboard";
import MtLibrary from "./components/pages/mtlibrary";
import IssueKit from "./components/pages/issuekit";
import ReturnKit from "./components/pages/returnkit";
import IssuedKitLog from "./components/pages/issuedkitlog";
import AddStudent from "./components/pages/addstudent";
import AddSchool from "./components/pages/addschool";
import UserDirectory from "./components/pages/userDirectory";
import NoticeBoard from "./components/pages/noticeboard";
import IndividualLog from "./components/pages/individuallog";
import IndividualNotice from "./components/pages/individualNotice";
import ActivateSchool from "./components/pages/activateschool";
import SchoolDirectory from "./components/pages/schooldirectory";
import Login from "./components/pages/login";
import { useSelector } from "react-redux";
import Feesdirectory from "./components/pages/feesdirectory";
import Feestemplate from "./components/pages/feestemplate";
import FeesCollectionMethods from "./components/pages/feesCollectionMethods";

function App() {
  const userDetails = useSelector((state) => state.auth.value);
  console.log(userDetails);

  return (
    <BrowserRouter className="App" r>
      <Routes>
        {userDetails === 0 ? (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/montlibrary" element={<MtLibrary />} />
            <Route path="/montlibrary/issuekit" element={<IssueKit />} />
            <Route path="/montlibrary/returnkit" element={<ReturnKit />} />
            <Route
              path="/montlibrary/issuedkitlog"
              element={<IssuedKitLog />}
            />
            <Route
              path="/montlibrary/issuedkitlog/individuallog"
              element={<IndividualLog />}
            />
            <Route path="/addstudent" element={<AddStudent />} />
             <Route path="/addschool" element={<AddSchool />} />
             <Route path="/userdirectory" element={<UserDirectory />} />
             <Route path="/feesdirectory" element={<Feesdirectory />} />
             <Route path="/feestemplates" element={<Feestemplate />} />
             <Route path="/feescollectionmethods" element={<FeesCollectionMethods/>} />
            <Route path="/schooldirectory" element={<SchoolDirectory />} />
            <Route path="/noticeboard" element={<NoticeBoard />} />
            <Route
              path="/noticeboard/individualnotice"
              element={<IndividualNotice />}
            />
            <Route path="/activateschool" element={<ActivateSchool />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

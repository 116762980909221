// Dependencies imports
import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { display, styled } from "@mui/system";

// Local imports
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

// Style imports
import Ad from "./addstudent.module.scss";
import Ed from "./editStudentModal.module.scss";

const useStyles = (theme) => {
  return {
    backdrop: styled("div")({
      zIndex: theme?.zIndex?.drawer + 1,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const EditStudenModal = (props) => {
  const { studentDetails, handleEditStudentModalClose, getDataGridData } =
    props;
  let userDetails = useSelector(authData);
  console.log("studentDetails", studentDetails);
  const [studentEmail, setStudentEmail] = useState(studentDetails.email);
  const [studentName, setStudentName] = useState(studentDetails.name);
  const [contact, setContact] = useState(studentDetails.phone.toString());
  const [level, setLevel] = useState(studentDetails.level);
  const [isActive, setIsActive] = useState(studentDetails.is_active);
  const [password, setPassword] = useState("");
  const [reEnterPass, setReEnterPass] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [reEnterPassErr, setReEnterPassErr] = useState("");
  const [studentNameErr, setStudentNameErr] = useState("");
  const [contactErr, setContactErr] = useState("");
  const [levelErr, setLevelErr] = useState("");
  const [studentEmailErr, setStudentEmailErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [error, setError] = useState("");
  const [paymentLink, setPaymentLink] = useState("");

  const [discount, setDiscount] = useState("");
  const [feesItems, setFeesItems] = useState(
    studentDetails.fees_details ? studentDetails.fees_details.installments : []
  );

  const [feesPaidItems, setFeesPaidItems] = useState(
    studentDetails.fees_payments ? studentDetails.fees_payments.payments : []
  );

  const [selectedFile, setSelectedFile] = useState("");
  const [paymentScreenshotUrls, setPaymentScreenshotUrls] = useState(
    studentDetails.fees_payments
      ? studentDetails.fees_payments.payments.map(
          (payment) => payment.payment_screenshot
        )
      : []
  );

  var lenFeesPaidPayments = studentDetails.fees_payments
    ? studentDetails.fees_payments.payments.length
    : 0;

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setStudentNameErr("");
    setStudentEmailErr("");
    setContactErr("");
    setLevelErr("");

    if (
      studentName === "" ||
      studentName === null ||
      studentName.trim() === ""
    ) {
      formIsValid = false;
      setStudentNameErr("Please enter student's name");
      return;
    }

    if (
      studentEmail === "" ||
      studentEmail === null ||
      studentEmail.trim() === ""
    ) {
      formIsValid = false;
      setStudentEmailErr("Email cannot be empty");
    }

    if (contact === "" || contact === null || contact.trim() === "") {
      formIsValid = false;
      setContactErr("Please enter Parent contact number");
      return;
    }
    if (
      level === "" ||
      level === null ||
      level.trim() === "" ||
      level === "select level"
    ) {
      formIsValid = false;
      setLevelErr("Please select class");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      editStudent();
      return;
    }
  };

  // Function to validate login form
  const validateResetForm = () => {
    console.log("Inside Validate reset FormData");
    let formIsValid = true;
    setPasswordErr("");
    setReEnterPassErr("");
    setError("");

    // Check if any date input is in the past
    for (const feeItem of feesItems) {
      if (new Date(feeItem.due_date) < new Date()) {
        formIsValid = false;
        setError("Installment date is invalid");
        break;
      }
    }

    let totalInstallmentsAmount = 0;
    let totalPaidAmount = 0;
    for (const feeItem of feesItems) {
      totalInstallmentsAmount += parseFloat(feeItem.amount);
      totalPaidAmount += parseFloat(feeItem.paid_amount);
      if (parseFloat(feeItem.paid_amount) > parseFloat(feeItem.amount)) {
        formIsValid = false;
        setError("Paid amount exceeds installment amount");
      }
    }

    if (totalPaidAmount > totalInstallmentsAmount) {
      formIsValid = false;
      setError("Total amount of installments paid exceeds total fees");
    }

    if (formIsValid === true) {
      return;
    }
  };

  const calculateFeesDue = () => {
    var installmentDueAmount = 0;
    var installmentDueDate = "No Due";
    var totalPaidAmount = 0;
    var agreedFees = "NA";

    if (studentDetails["fees_details"] !== null) {
      var installments = studentDetails["fees_details"]["installments"];
      var feesPaid =
        studentDetails["fees_payments"] !== null
          ? studentDetails["fees_payments"]["payments"]
          : 0;
      var totalFeesPaidAmount =
        feesPaid !== 0
          ? feesPaid.reduce((acc, feesPaidItem) => {
              return acc + feesPaidItem.paid_amount;
            }, 0)
          : 0;
      var installmentAmount = 0;

      var currentDate = new Date();
      for (var j = 0; j < installments.length; j++) {
        agreedFees += installments[j]["amount"];
        if (new Date(installments[j]["due_date"]) <= currentDate) {
          installmentAmount += installments[j]["amount"];
          installmentDueDate = installments[j]["due_date"];
        }
      }

      installmentDueAmount =
        installmentAmount - totalFeesPaidAmount >= 0
          ? installmentAmount - totalFeesPaidAmount
          : 0;
      return installmentDueAmount;
    }
  };

  const sendReminderEmail = (paymentLink) => {
    setSpin(true);
    let formdata = new FormData();
    formdata.append("student_email", studentDetails.email);
    formdata.append("school_code", userDetails.school_code);
    formdata.append("payment_link", paymentLink);

    axios
      .post(`${Constants.api_url}/fees_installments/reminder_email`, formdata)
      .then((response) => {
        setSpin(false);
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              handleEditStudentModalClose();
              getDataGridData();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      });
  };

  const updateInstallment = () => {};

  const updatePassword = () => {
    setSpin(true);

    let formdata = new FormData();
    formdata.append("email", studentEmail);
    formdata.append("password", password);
    formdata.append("re_password", reEnterPass);

    axios
      .post(`${Constants.api_url}/auth/password/set`, formdata)
      .then((response) => {
        console.log("result", response.data);
        setSpin(false);
        if (response.data.code === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              handleEditStudentModalClose();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const validateFeesPaidForm = () => {
    let formIsValid = true;
    setError("");

    // Check if any input date is empty or any paid amount is 0
    for (const feesPaid of feesPaidItems) {
      console.log("Fees Paid Item: ", feesPaid);
      if (
        feesPaid.paid_date === "" ||
        feesPaid.paid_amount === "" ||
        feesPaid.paid_amount === 0
      ) {
        formIsValid = false;
        setError("Payment date or Payment Amount is Invalid!");
        break;
      }
    }

    if (formIsValid === true) {
      const newFeesPaidItems = feesPaidItems.filter((feesPaidItem) => {
        if (!studentDetails.fees_payments) {
          return feesPaidItem;
        }
        return !studentDetails.fees_payments.payments.some(
          (dbFeesPaidItems) => {
            return (
              dbFeesPaidItems.paid_date === feesPaidItem.paid_date &&
              dbFeesPaidItems.paid_amount === feesPaidItem.paid_amount
            );
          }
        );
      });
      console.log("New Fees Paid Items: ", newFeesPaidItems);
      newFeesPaidItems.forEach((newFeesPaidItem) => {
        updateFeesPaidInDB(newFeesPaidItem);
      });
      return;
    }
  };

  function editStudent() {
    return;
  }

  //API to issue kits
  const updateFeesPaidInDB = (feesPaidItem) => {
    setSpin(true);

    let formdata = new FormData();
    formdata.append("email", studentDetails.email);
    formdata.append("paid_amount", feesPaidItem.paid_amount);
    formdata.append("paid_date", feesPaidItem.paid_date);
    if (feesPaidItem.payment_screenshot !== "") {
      formdata.append(
        "payment_screenshot",
        feesPaidItem.payment_screenshot,
        feesPaidItem.payment_screenshot.name
      );
    }

    axios
      .post(`${Constants.api_url}/fees_installments/add_payment`, formdata)
      .then((response) => {
        console.log("result", response.data);
        setSpin(false);
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              handleEditStudentModalClose();
              getDataGridData();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const handleOnFileChange = (index, event) => {
    console.log("index: ", index);
    let file = event.target.files[0];

    const updatedFeesPaidItems = [...feesPaidItems];
    updatedFeesPaidItems[index]["payment_screenshot"] = file;
    updatedFeesPaidItems[index]["file_name"] = file.name;

    const newPaymentSSUrls = [...paymentScreenshotUrls];
    newPaymentSSUrls[index] = URL.createObjectURL(file);
    setPaymentScreenshotUrls(newPaymentSSUrls);
    setFeesPaidItems(updatedFeesPaidItems);
    console.log("pymt ssurls: ", newPaymentSSUrls);
    console.log("feesPaidItems: ", updatedFeesPaidItems);
  };

  const handleFeesItemChange = (index, key, value) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems[index][key] = value;
    setFeesItems(updatedFeesItems);
  };

  const addFeesItem = () => {
    setFeesItems([...feesItems, { due_date: "", amount: "" }]);
  };

  const handleFeesPaidChange = (index, key, value) => {
    const updatedFeesPaidItems = [...feesPaidItems];
    updatedFeesPaidItems[index][key] =
      key === "paid_amount" ? parseFloat(value) : value;

    setFeesPaidItems(updatedFeesPaidItems);
  };

  const addFeesPaidItem = () => {
    setFeesPaidItems([
      ...feesPaidItems,
      {
        paid_date: "",
        paid_amount: "",
        payment_screenshot: null,
        file_name: "",
      },
    ]);
  };

  const deleteFeesItem = (index) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems.splice(index, 1);
    setFeesItems(updatedFeesItems);
  };

  const deleteFeesPaidItem = (index) => {
    const updatedFeesPaidItems = [...feesPaidItems];
    updatedFeesPaidItems.splice(index, 1);
    setFeesPaidItems(updatedFeesPaidItems);
    const newPaymentSSUrls = [...paymentScreenshotUrls];
    newPaymentSSUrls.splice(index, 1);
    setPaymentScreenshotUrls(newPaymentSSUrls);
  };

  return (
    <div className={Ed.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Ed.topbar}>
        <Close
          className={Ad.backbutton}
          onClick={handleEditStudentModalClose}
        />
      </div>

      <div className={Ad.centerformholder} style={{ paddingInline: "15vw" }}>
        <div className={Ad.inputblock}>
          <label for="studentname" className={Ad.formlabel}>
            Student Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="studentname"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          />
          <h4 className={Ad.errormessage}>{studentNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="studentemail" className={Ad.formlabel}>
            Student Email
          </label>
          <input
            type="email"
            className={Ad.inputtext}
            id="studentemail"
            value={studentEmail}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          />
          <h4 className={Ad.errormessage}>{studentEmailErr}</h4>
        </div>

        <div className={Ad.inputblock}>
          <label for="parentphonenumber" className={Ad.formlabel}>
            Parent Phone Number
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="parentphonenumber"
            onChange={(e) => setContact(e.target.value)}
            value={contact}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          />
          <h4 className={Ad.errormessage}>{contactErr}</h4>
        </div>
        {/* <div className={Ad.inputblock}>
          <label for="parentphonenumber" className={Ad.formlabel}>
            Parent Phone Number
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="parentphonenumber"
            onChange={(e) => setContact(e.target.value)}
            value={contact}
          />
          <h4 className={Ad.errormessage}>{contactErr}</h4>
        </div> */}
        <div className={Ad.inputblock}>
          <label className={Ad.formlabel}>Student Class</label>
          <select
            className={Ad.selectblock}
            value={level}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          >
            <option selected>Select Class</option>
            <option value="playgroup">Playgroup</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
          </select>
          <h4 className={Ad.errormessage}>{levelErr}</h4>
        </div>
        {/* <div className={Ad.savebuttonholder}>
          <button
            className={Ad.savebutton}
            onClick={() => {
              validateForm();
            }}
          >
            Save Changes
          </button>
        </div> */}
      </div>
      <div className={Ad.resetformholder} style={{ marginInline: "15vw" }}>
        <label className={Ad.formlabel} style={{ alignSelf: "start" }}>
          Installments
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              textAlign: "left",
              fontWeight: "600",
            }}
          >
            Due Date
          </p>
          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Installment <br /> Amount
          </p>
          <p
            style={{
              textAlign: "right",
              fontWeight: "600",
            }}
          >
            Remarks
          </p>
        </div>
        {feesItems.map((feesItem, index) => (
          <div key={index} className={Ad.feesitemblock}>
            <p
              className={Ad.inputtext}
              style={{
                height: "50px",
                alignContent: "center",
                justifyContent: "left",
                fontSize: "18px",
                fontWeight: "600",
                cursor: "not-allowed",
                pointerEvents: "none",
                color: "grey",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              {feesItem.due_date}
            </p>

            <p
              className={Ad.inputtext}
              style={{
                height: "50px",
                alignContent: "center",
                justifyContent: "left",
                fontSize: "18px",
                fontWeight: "600",
                cursor: "not-allowed",
                pointerEvents: "none",
                color: "grey",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              {feesItem.amount}
            </p>

            <p
              className={Ad.inputtext}
              style={{
                height: "50px",
                alignContent: "right",
                justifyContent: "center",
                fontSize: "18px",
                fontWeight: "600",
                cursor: "not-allowed",
                pointerEvents: "none",
                color: "grey",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              {feesItem.installment_name}
            </p>

            <h4 className={Ad.errormessage}>{levelErr}</h4>

            {/* <input
              type="number"
              placeholder="Amount"
              className={Ad.inputfeesitem}
              value={feesItem.amount}
              onChange={(e) =>
                handleFeesItemChange(index, "amount", e.target.value)
              }
              style={{
                fontSize: "18px",
                fontWeight: "600",
                cursor: "not-allowed",
                pointerEvents: "none",
                color: "grey",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            />

            <input
              type="text"
              placeholder="Remarks"
              className={Ad.inputfeesitem}
              value={feesItem.installment_name}
              onChange={(e) =>
                handleFeesItemChange(index, "installment_name", e.target.value)
              }
              style={{
                fontSize: "18px",
                fontWeight: "600",
                cursor: "not-allowed",
                pointerEvents: "none",
                color: "grey",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            /> */}
          </div>
        ))}
      </div>

      {/* ------------------------------------ Payment Details ------------------------------------ */}

      <div
        className={Ad.resetformholder}
        style={{ marginTop: "40px", marginInline: "15vw" }}
      >
        <label className={Ad.formlabel} style={{ alignSelf: "start" }}>
          Payments Received
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              textAlign: "left",
              fontWeight: "600",
            }}
          >
            Received Date
          </p>
          <p
            style={{
              textAlign: "left",
              fontWeight: "600",
            }}
          >
            Paid Amount
          </p>
          <p
            style={{
              textAlign: "left",
              fontWeight: "600",
            }}
          >
            Payment Screenshot
          </p>
          <p
            style={{
              textAlign: "right",
              fontWeight: "600",
            }}
          ></p>
        </div>
        {feesPaidItems.map((feesPaid, index) => (
          <div key={index} className={Ad.feesitemblock}>
            <input
              type="date"
              name="paid_date"
              className={Ad.inputtext}
              value={feesPaid.paid_date}
              onChange={(e) =>
                handleFeesPaidChange(index, "paid_date", e.target.value)
              }
              style={{
                fontSize: "18px",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            />

            <input
              type="number"
              name="paid_amount"
              placeholder="Amount"
              className={Ad.inputfeesitem}
              value={feesPaid.paid_amount}
              onChange={(e) =>
                handleFeesPaidChange(index, "paid_amount", e.target.value)
              }
              style={{
                fontSize: "18px",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            />

            <div
              style={{
                width: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <label
                for={`payment_ss_file_${index}`}
                class="btn"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  width: "100%",
                  cursor: "pointer",
                  border: "1px solid",
                  display: "inline-block",
                  backgroundColor: "#f0f0f0",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {paymentScreenshotUrls[index] != null ? (
                  <a
                    href={paymentScreenshotUrls[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Image
                  </a>
                ) : (
                  "Upload"
                )}
              </label>
              <input
                id={`payment_ss_file_${index}`}
                type="file"
                name="payment_screenshot"
                // className={Ad.inputfeesitem}
                placeholder="Image"
                accept="image/*"
                onChange={(e) => handleOnFileChange(index, e)}
                style={{
                  fontSize: "18px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  visibility: "hidden",
                }}
              />
            </div>

            {index >= lenFeesPaidPayments ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1vw",
                }}
              >
                <DeleteIcon
                  className={Ad.actionicons}
                  style={{ height: "fitcontent" }}
                  onClick={() => {
                    deleteFeesPaidItem(index);
                  }}
                />
              </div>
            ) : null}
          </div>
        ))}
        {isActive === true ? (
          <button onClick={addFeesPaidItem} className={Ad.addbutton}>
            + Add New Payment Received
          </button>
        ) : (
          <button
            onClick={() => {}}
            className={Ad.addbutton}
            style={{
              opacity: 0.6,
              cursor: "not-allowed",
            }}
          >
            + Add New Payment Received
          </button>
        )}

        <h4 className={Ad.errormessage}>{error}</h4>

        <div className={Ad.savebuttonholder}>
          {isActive === true ? (
            <button
              className={Ad.savebutton}
              onClick={() => {
                validateFeesPaidForm();
              }}
            >
              Save Changes
            </button>
          ) : (
            <button
              className={Ad.savebutton}
              style={{
                opacity: 0.6,
                cursor: "not-allowed",
              }}
              onClick={() => {}}
            >
              Save Changes
            </button>
          )}
        </div>
      </div>

      {/* <div
        className={Ad.resetformholder}
        style={{ marginTop: "40px", marginInline: "15vw" }}
      >
        <label className={Ad.formlabel} style={{ alignSelf: "start" }}>
          Send Fees Due Reminder Email
        </label>
        <div className={Ad.inputblock} style={{ alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            <p
              style={{
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              Due Amount:{" "}
              <span style={{ fontWeight: "800" }}>
                {isActive ? calculateFeesDue().toLocaleString() : "NA"}
              </span>
            </p>
          </div>

          <input
            type="text"
            name="payment_link"
            className={Ad.inputtext}
            onChange={(e) => {
              setPaymentLink(e.target.value);
            }}
            placeholder="Payment Link"
            style={{
              fontSize: "18px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          />

          <div className={Ad.savebuttonholder}>
            {isActive ? (
              <button
                className={Ad.savebutton}
                onClick={() => {
                  sendReminderEmail(paymentLink);
                }}
              >
                Send Reminder Email
              </button>
            ) : (
              <button
                className={Ad.savebutton}
                style={{
                  opacity: 0.6,
                  cursor: "not-allowed",
                }}
                onClick={() => {}}
              >
                Send Reminder Email
              </button>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default EditStudenModal;

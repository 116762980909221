import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import swal from "sweetalert";

// Local imports
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Styles imports
import Co from "./confirmationModal.module.scss";

const ConfirmationModal = (props) => {
  const { handleConfirmationModalClose, Details, getDataGridData, type } =
    props;
  let userDetails = useSelector(authData);

  console.log("details received", Details);

  const deleteSchool = () => {
    var config = {
      method: "delete",
      url: `${Constants.api_url}/schools/delete/${Details.school_code}/${Details.admin_email}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        if (result.data.code === 200) {
          swal({
            title: "Success",
            text: result.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              getDataGridData();
              handleConfirmationModalClose();
            }
          });
        } else {
          swal({
            title: "Oops",
            text: result.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch(function (error) {
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  console.log("Tpye of user", type);

  const deleteUser = () => {
    var config = {
      method: "delete",
      url: `${Constants.api_url}/users/delete/${userDetails.school_code}/${Details.email}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        if (result.data.code === 200) {
          swal({
            title: "Success",
            text: result.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              getDataGridData();
              handleConfirmationModalClose();
            }
          });
        } else {
          swal({
            title: "Oops",
            text: result.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch(function (error) {
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={Co.maincontainer}>
      <h1 className={Co.heading}>
        Are you sure you want to delete this {type}?
      </h1>
      <div className={Co.buttonsholder}>
        <button
          className={Co.cancelbutton}
          onClick={handleConfirmationModalClose}
        >
          No
        </button>
        <button
          className={Co.deletebutton}
          onClick={type === "school" ? deleteSchool : deleteUser}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;

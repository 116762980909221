import React, { useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import qs from "qs";

// Local imports
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Styles imports
import Co from "./confirmationModal.module.scss";

const SaveConfirmationModal = (props) => {
  const {
    handleConfirmationModalClose,
    data,
    setStudentName,
    setStudentEmail,
    setContact,
    setLevel,
    setSelectedTemplate,
    setFeesItems,
    setDiscount,
    settotalFeesafterdiscount,
    setTotalFeesBeforeDiscount,
    setStudentId,
  } = props;
  let userDetails = useSelector(authData);

  console.log("details received", data);
  const buttonRef = useRef(null);

  const addStudent = () => {
    if (buttonRef.current && !buttonRef.current.disabled) {
      buttonRef.current.disabled = true;
      const config = {
        method: "POST",
        url: `${Constants.api_url}/users/initial/student`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function (result) {
          if (result.data.code === 200) {
            swal({
              title: "Success",
              text: "User added successfully",
              icon: "success",
              dangerMode: false,
              closeOnClickOutside: false,
            }).then(() => {
              setStudentName("");
              setStudentEmail("");
              setContact("");
              setLevel("");
              setFeesItems([]);
              setDiscount("");
              settotalFeesafterdiscount(0);
              setTotalFeesBeforeDiscount(0);
              setSelectedTemplate("");
              setStudentId("");
              handleConfirmationModalClose();
            });
          } else {
            swal({
              title: "Warning!",
              text: "User with email already exists",
              icon: "warning",
              dangerMode: false,
              closeOnClickOutside: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);

          swal({
            title: "Oops!",
            text: "Something went wrong",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          });
        });
    }
  };

  return (
    <div className={Co.maincontainer}>
      <h1 className={Co.heading}>
        Add the student? <br />
        An email will be sent with fees details.
      </h1>
      <div className={Co.buttonsholder}>
        <button
          className={Co.cancelbutton}
          onClick={handleConfirmationModalClose}
        >
          No
        </button>
        <button
          className={Co.deletebutton}
          onClick={addStudent}
          ref={buttonRef}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default SaveConfirmationModal;

// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";

// Local imports
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

// Style imports
import Ad from "./addstudent.module.scss";
import Ed from "./editStudentModal.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const EditSchoolModal = (props) => {
  const { handleEditStudentModalClose, schoolDetails, getDataGridData } = props;
  console.log("schooldetails in modal", schoolDetails);
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [schoolName, setSchoolName] = useState(schoolDetails.school_name);
  const [adminName, setAdminName] = useState(schoolDetails.admin_name);
  const [adminEmail, setAdminEmail] = useState(schoolDetails.admin_email);
  const [adminPhone, setAdminPhone] = useState(
    schoolDetails.admin_phone.toString()
  );
  const [password, setPassword] = useState("");
  const [city, setCity] = useState(schoolDetails.city);
  const [state, setState] = useState(schoolDetails.state);
  const [pincode, setPincode] = useState(schoolDetails.pincode.toString());
  const [reEnterPass, setReEnterPass] = useState("");
  const [schoolCode, setSchoolCode] = useState(schoolDetails.school_code);
  const [schoolNameErr, setSchoolNameErr] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [adminPhoneErr, setAdminPhoneErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [schoolCodeErr, setSchoolCodeErr] = useState("");
  const [reEnterPassErr, setReEnterPassErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [adminNameErr, setAdminNameErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [pinErr, setPinErr] = useState("");
  const [schoolLogoUrl, setSchoolLogoUrl] = useState(schoolDetails.school_logo);

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setSchoolNameErr("");
    setEmailErr("");
    setSchoolCodeErr("");
    setPasswordErr("");
    setReEnterPassErr("");

    if (schoolName === "" || schoolName === null || schoolName.trim() === "") {
      formIsValid = false;
      setSchoolNameErr("School Name cannot be empty");
      return;
    }
    if (adminEmail === "" || adminEmail === null || adminEmail.trim() === "") {
      formIsValid = false;
      setEmailErr("Email cannot be empty");
      return;
    }
    if (schoolCode === "" || schoolCode === null || schoolCode.trim() === "") {
      formIsValid = false;
      setSchoolCodeErr("School code cannot be empty");
      return;
    }

    if (adminName === "" || adminName === null || adminName.trim() === "") {
      formIsValid = false;
      setAdminNameErr("Admin Name cannot be empty");
    }

    if (adminPhone === "" || adminPhone === null || adminPhone.trim() === "") {
      formIsValid = false;
      setAdminPhoneErr("Contact cannot be empty");
      return;
    }
    if (password !== "") {
      if (
        reEnterPass === "" ||
        reEnterPass === null ||
        reEnterPass.trim() === ""
      ) {
        formIsValid = false;
        setReEnterPassErr("Please Re-enter the password");
        return;
      }
      if (reEnterPass !== password) {
        formIsValid = false;
        setReEnterPassErr("Password is not matching");
        return;
      }
    }

    if (city === "" || city === null || city.trim() === "") {
      formIsValid = false;
      setCityErr("Please enter the city");
      return;
    }

    if (state === "" || state === null || state.trim() === "") {
      formIsValid = false;
      setStateErr("Please enter the state");
      return;
    }

    if (pincode === "" || pincode === null || pincode.trim() === "") {
      formIsValid = false;
      setPincode("Please enter a pincode");
      return;
    }

    if (formIsValid === true) {
      console.log("form is valid");
      checkForSchoolLogoUpdate();
      return;
    }
  };

  // Function to validate login form
  const validateResetForm = () => {
    let formIsValid = true;
    setPasswordErr("");
    setReEnterPassErr("");

    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPasswordErr("Password Cannot be empty");
      return;
    }
    if (
      reEnterPass === "" ||
      reEnterPass === null ||
      reEnterPass.trim() === ""
    ) {
      formIsValid = false;
      setReEnterPassErr("Please Re-enter the password");
      return;
    }
    if (reEnterPass !== password) {
      formIsValid = false;
      setReEnterPassErr("Password is not matching");
      return;
    }

    if (formIsValid === true) {
      updatePassword();
      return;
    }
  };

  const checkForSchoolLogoUpdate = () => {
    if (selectedFile !== "") {
      updateSchoolLogo();
    } else {
      editSchool();
    }
  };

  const updatePassword = () => {
    setSpin(true);

    let formdata = new FormData();
    formdata.append("email", adminEmail);
    formdata.append("password", password);
    formdata.append("re_password", reEnterPass);

    axios
      .post(`${Constants.api_url}/auth/password/set`, formdata)
      .then((response) => {
        console.log("result for password reset", response.data);
        setSpin(false);
        console.log("body inside", response.data.body);
        if (response.data.code === 207) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              handleEditStudentModalClose();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const updateSchoolLogo = () => {
    setSpin(true);
    var formdata = new FormData();
    formdata.append("school_logo", selectedFile, selectedFile.name);

    var requestOptions = {
      method: "put",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${Constants.api_url}/schools/update/logo?school_code=${schoolDetails.school_code}`,
      requestOptions
    )
      .then((result) => {
        console.log("result", result);
        setSpin(false);
        if (result.status === 200) {
          editSchool();
        } else {
          swal({
            title: "Oops",
            text: "Something went wrong!",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const handleOnChange = (event) => {
    let files = event.target.files;
    console.log("files", files);
    setSelectedFile(files[0]);
  };

  //API to issue kits
  const editSchool = () => {
    setSpin(true);

    var data = JSON.stringify({
      school_name: schoolName.toString(),
      city: city.toString(),
      state: state.toString(),
      pincode: pincode.toString(),
      admin_name: adminName.toString(),
      admin_phone: adminPhone.toString(),
    });

    var config = {
      method: "put",
      url: `${Constants.api_url}/schools/update/${schoolDetails.school_code}/${schoolDetails.admin_email}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log("result for update school", result);
        if (result.data.code === 200) {
          swal({
            title: "Success",
            text: result.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              getDataGridData();
              handleEditStudentModalClose();
              setSpin(false);
            }
          });
        } else {
          swal({
            title: "Oops",
            text: result.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
        }
      })
      .catch(function (error) {
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      });
  };

  return (
    <div className={Ed.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Ed.topbar}>
        <Close
          className={Ad.backbutton}
          onClick={handleEditStudentModalClose}
        />
      </div>
      <div className={Ad.centerformholder}>
        <div className={Ad.inputblock}>
          <label for="schoolcode" className={Ad.formlabel}>
            School Code
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="schoolcode"
            value={schoolCode}
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
          />
          <h4 className={Ad.errormessage}>{schoolCodeErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="schoolname" className={Ad.formlabel}>
            School Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="schoolname"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          <h4 className={Ad.errormessage}>{schoolNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label className={Ad.formlabel}>School logo</label>
          <input
            type="file"
            onChange={(event) => handleOnChange(event)}
            className={Ad.fileinput}
          />
        </div>
        <div className={Ad.inputblock} style={{ marginTop: "20px" }}>
          <label for="name" className={Ad.formlabel}>
            Admin Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="name"
            onChange={(e) => setAdminName(e.target.value)}
            value={adminName}
          />
          <h4 className={Ad.errormessage}>{adminNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="email" className={Ad.formlabel}>
            Admin Email
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="email"
            style={{
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
            }}
            value={adminEmail}
          />
          <h4 className={Ad.errormessage}>{emailErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="contact" className={Ad.formlabel}>
            Admin Phone
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="contact"
            onChange={(e) => setAdminPhone(e.target.value)}
            value={adminPhone}
          />
          <h4 className={Ad.errormessage}>{adminPhoneErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="city" className={Ad.formlabel}>
            City
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="city"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
          <h4 className={Ad.errormessage}>{cityErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="state" className={Ad.formlabel}>
            State
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="state"
            onChange={(e) => setState(e.target.value)}
            value={state}
          />
          <h4 className={Ad.errormessage}>{stateErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="pincode" className={Ad.formlabel}>
            Pincode
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="pincode"
            onChange={(e) => setPincode(e.target.value)}
            value={pincode}
          />
          <h4 className={Ad.errormessage}>{pinErr}</h4>
        </div>

        <div className={Ad.savebuttonholder}>
          <button
            className={Ad.savebutton}
            onClick={() => {
              validateForm();
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
      <div className={Ad.resetformholder}>
        <div className={Ad.inputblock}>
          <label for="email" className={Ad.formlabel}>
            Reset Password
          </label>
          <input
            type="password"
            className={Ad.inputtext}
            id="email"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <h4 className={Ad.errormessage}>{passwordErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="reenterpassword" className={Ad.formlabel}>
            Re-enter Password
          </label>
          <input
            type="password"
            className={Ad.inputtext}
            id="reenterpassword"
            onChange={(e) => setReEnterPass(e.target.value)}
            value={reEnterPass}
          />
          <h4 className={Ad.errormessage}>{reEnterPassErr}</h4>
        </div>
        <div className={Ad.savebuttonholder}>
          <button
            className={Ad.savebutton}
            onClick={() => {
              validateResetForm();
            }}
          >
            Save Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSchoolModal;

// Dependencies import
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import qs from "qs";
import swal from "sweetalert";

// Local imports
import LogoImage from "../../assets/logo/logo.png";
import { stateUpdate, authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Style imports
import Lo from "./login.module.scss";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  const storeUserData = (userRes) => {
    let userData = { ...userDetails };
    userData.email = userRes.user.email;
    userData.username = userRes.user.name;
    userData.school_code = userRes.user.school_code;
    userData.role = userRes.user.role;
    userData.school_name = userRes.school.school_name;
    userData.school_logo = userRes.school.shool_logo;
    dispatch(stateUpdate(userData));
  };

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setEmailErr("");
    setPassErr("");

    if (email === "" || email === null || email.trim() === "") {
      formIsValid = false;
      setEmailErr("Please enter your email");
      return;
    }
    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPassErr("Please enter your password");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      loginUser();

      return;
    }
  };

  //API to send user details
  const loginUser = () => {
    var data = qs.stringify({
      email: email.toString(),
      password: password.toString(),
    });

    var config = {
      method: "POST",
      url: `${Constants.api_url}/auth/login`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);

        if (result.data.code === 401) {
          swal({
            title: "Oops!",
            text: result.data.message,
            icon: "warning",
            dangerMode: true,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        } else {
          storeUserData(result.data);
          navigate("/dashboard");
          swal({
            title: "Success",
            text: "Logged in successfully",
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        }
      })
      .catch(function (error) {
        swal({
          title: "Oops!",
          text: "Incorrect email/password",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={Lo.maincontainer}>
      <h3 className={Lo.loginheading}>Sign in</h3>
      <img src={LogoImage} alt="logo" className={Lo.brightbeeplogo} />
      <div className={Lo.formholder}>
        <div className={Lo.inputholder}>
          <label className={Lo.formlabel}>Email</label>
          <input
            type="text"
            className={Lo.forminput}
            value={email}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                validateForm();
              }
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <h4 className={Lo.errormessage}>{emailErr}</h4>
        </div>
        <div className={Lo.inputholder}>
          <label className={Lo.formlabel}>Password</label>
          <input
            type="password"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                validateForm();
              }
            }}
            className={Lo.forminput}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <h4 className={Lo.errormessage}>{passErr}</h4>
        </div>
      </div>
      <div className={Lo.loginbuttonholder}>
        <button className={Lo.loginbutton} onClick={() => validateForm()}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;

// Dependencies imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import moment from "moment";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as IssuedIcon } from "../../assets/icons/issuedicon.svg";
import { ReactComponent as DoubleRightArrow } from "../../assets/icons/doublerightarrow.svg";
import { stateUpdate, authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Style imports
import Is from "./mtlibrary.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const IssuedKitLog = () => {
  const navigate = useNavigate();
  const [spin, setSpin] = useState(true);
  const [responseList, setResponseList] = useState([]);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();

  // Useeffect for api call
  useEffect(() => {
    getKits();
    //eslint-disable-next-line
  }, []);

  //API to get product details
  const getKits = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/montlib/all_issues/${userDetails.school_code}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        setResponseList(result.data);
        console.log("response", result.data);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const updateDataToRedux = (value) => {
    let userData = { ...userDetails };
    let obj = {};
    obj.kit_name = value.kit_name;
    obj.issue_date = value.issue_date;
    obj.return_date = value.return_date;
    obj.student_level = value.student_level;
    obj.student_name = value.student_name;
    userData.kit_details = obj;
    dispatch(stateUpdate(userData));
  };

  return (
    <div className={Is.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Is.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Is.backbutton} />
      </div>
      <div className={Is.headingholder}>
        <IssuedIcon />
        <h3 className={Is.heading}>Issued Kit Log</h3>
      </div>
      <div className={Is.navigationcardholders}>
        {responseList.map((value, i) => {
          return (
            <div
              className={
                value.return_date === null
                  ? Is.individualcardpink
                  : Is.individualcardgreen
              }
              onClick={() => {
                updateDataToRedux(value);
                navigate("/montlibrary/issuedkitlog/individuallog");
              }}
            >
              <div className={Is.leftcard}>
                <h4 className={Is.navheading}>{value.kit_name}</h4>
              </div>
              <div className={Is.issuedonholder}>
                <h4 className={Is.navheading}>
                  Issued On: {moment(value.issue_date).format("L")}
                </h4>
                <h4 className={Is.navheading}>
                  Issued to: {value.student_name}
                </h4>
              </div>
              <DoubleRightArrow className={Is.doublearrow} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IssuedKitLog;

// Dependencies imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import qs from "qs";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/addicon.svg";
import NoticeBoardIcon from "../../assets/icons/noticeboardicon.png";
import { stateUpdate, authData } from "../../redux/auth/authSlice";
import AddNotice from "./addNotice";
import { Constants } from "../../constants";

// Styles imports
import No from "./noticeboard.module.scss";

const ModalContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
});

const BackdropContainer = styled("div")(({ theme }) => ({
  zIndex: theme?.zIndex?.drawer + 1,
  color: "#fff",
}));

const DataGridContainer = styled("div")({
  fontSize: "14px",
  fontFamily: "nunito",
  fontWeight: 400,
  color: "black",
  cursor: "pointer",
});

const NoticeBoard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [spin, setSpin] = useState(true);
  const [notices, setNotices] = useState([]);
  let userDetails = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();

  // Useeffect for api call
  useEffect(() => {
    getNoticeBoard();
    //eslint-disable-next-line
  }, []);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  //API to get product details
  const getNoticeBoard = () => {
    setSpin(true);

    var data = qs.stringify({
      school_code: userDetails.school_code.toString(),
    });

    var config = {
      method: "GET",
      url: `${Constants.api_url}/notices/all?school_code=${userDetails.school_code}&role=${userDetails.role}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        setNotices(result.data);
        console.log("response", result.data);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={No.maincontainer}>
      {/* <BackdropContainer open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </BackdropContainer> */}
      {spin && (
        <BackdropContainer>
          <CircularProgress style={{ color: "#226bf6" }} />
        </BackdropContainer>
      )}

      <ModalContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          // className={ModalContainer}
          open={open}
          closeAfterTransition
          BackdropComponent={BackdropContainer}
          BackdropProps={{
            timeout: 500,
            invisible: true,
          }}
        >
          {/* <Fade in={open}> */}
          <AddNotice
            handleClose={handleClose}
            getNoticeBoard={getNoticeBoard}
          />
          {/* </Fade> */}
        </Modal>
      </ModalContainer>

      <div className={No.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={No.backbutton} />
      </div>
      <div className={No.headingholder}>
        <div className={No.lefthead}>
          <img
            src={NoticeBoardIcon}
            alt="noticeboardicon"
            className={No.noticeboardicon}
          />
          <h3 className={No.heading}>Notice Board</h3>
        </div>
        <div className={No.righthead}>
          <button className={No.addbutton} onClick={() => handleOpen()}>
            <AddIcon />
            ADD
          </button>
        </div>
      </div>
      <div className={No.middleholder}>
        <div className={No.leftside}>
          {notices.map((value, i) => {
            return (
              <DataGridContainer
                className={No.topicnameholder}
                key={i}
                onClick={() => {
                  let userData = { ...userDetails };
                  userData.notice_title = value.title;
                  userData.notice_body = value.body;
                  userData.attachment = value.attachment;
                  dispatch(stateUpdate(userData));
                  navigate("/noticeboard/individualnotice");
                }}
              >
                <h3 className={No.topicname}>{value.title}</h3>
                <div className={No.dataholder}>
                  <div className={No.classholder}>
                    <h4 className={No.classname}>Class: {value.level}</h4>
                  </div>
                  <div className={No.createdholder}>
                    <h4 className={No.createdOn}>
                      Created On: {moment(value.created_at).format("L")}
                    </h4>
                  </div>
                </div>
              </DataGridContainer>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;

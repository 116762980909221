// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as ReturnIcon } from "../../assets/icons/returnicon.svg";
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";

// Style imports
import Re from "./issuekit.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const ReturnKit = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("Select Product");
  const [studentEmail, setStudentEmail] = useState("Select Student");
  const [studentList, setStudentList] = useState([]);
  const [studentClass, setStudentClass] = useState("Select Class");
  const [returnDate, setReturnDate] = useState("");
  const [productNameErr, setProductNameErr] = useState("");
  const [studentEmailErr, setStudentEmailErr] = useState("");
  const [studentClassErr, setStudentClassErr] = useState("");
  const [returnDateErr, setReturnDateErr] = useState("");
  const [comments, setComments] = useState("");
  const [commentsErr, setCommentsErr] = useState("");
  const [spin, setSpin] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  let userDetails = useSelector(authData);
  const classes = useStyles();

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setProductNameErr("");
    setStudentEmailErr("");
    setStudentClassErr("");
    setReturnDateErr("");
    setCommentsErr("");

    if (
      studentClass === "" ||
      studentClass === null ||
      studentClass === "Select Class" ||
      studentClass.trim() === ""
    ) {
      formIsValid = false;
      setStudentClassErr("Please select student class");
      return;
    }
    if (
      studentEmail === "" ||
      studentEmail === null ||
      studentEmail === "Select Student" ||
      studentEmail.trim() === ""
    ) {
      formIsValid = false;
      setStudentEmailErr("Please select a student");
      return;
    }
    if (
      productName === "" ||
      productName === null ||
      productName === "Select Product" ||
      productName.trim() === ""
    ) {
      formIsValid = false;
      setProductNameErr("Please select a product");
      return;
    }
    if (returnDate === "" || returnDate === null || returnDate.trim() === "") {
      formIsValid = false;
      setReturnDateErr("Please enter student's email");
      return;
    }
    if (comments === "" || comments === null || comments.trim() === "") {
      formIsValid = false;
      setCommentsErr("Please enter comments");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      returnKit();
      return;
    }
  };

  //API to get product details
  const getKits = (event) => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/montlib/find/${event}/${studentClass}/${userDetails.school_code}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        let temp = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i]["return_date"] === null) {
            temp.push(result.data[i]);
          }
        }
        setFilteredList(temp);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  //API to issue kits
  const returnKit = () => {
    setSpin(true);

    var config = {
      method: "PUT",
      url: `${
        Constants.api_url
      }/montlib/return/${studentEmail}/${2000000001}/${studentClass}/${
        userDetails.school_code
      }/${productName}/${productName}/${comments}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("result data", result.data);
        setSpin(false);
        if (result.data.code === 404) {
          swal({
            title: "Warning",
            text: "Student with email does not exists!",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        } else {
          swal({
            title: "Success",
            text: "Kit returned successfully",
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setProductName("Select Product");
              setStudentEmail("Select Student");
              setStudentClass("Select Class");
              setReturnDate("");
              setComments("");
              setStudentList([]);
              setFilteredList([]);
            }
          });
        }
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const getStudents = (event) => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `${Constants.api_url}/users/students/directory/?school_code=${userDetails.school_code}&level=${event}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("student list", result.data);
        setStudentList(result.data);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  return (
    <div className={Re.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Re.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Re.backbutton} />
      </div>
      <div className={Re.headingholder}>
        <ReturnIcon />
        <h3 className={Re.heading}>Return kit</h3>
      </div>
      <div className={Re.centerformholder}>
        <div className={Re.inputblock}>
          <label className={Re.formlabel}>Student Class</label>
          <select
            className={Re.selectblock}
            onChange={(event) => {
              setStudentClass(event.target.value);
              getStudents(event.target.value);
            }}
            value={studentClass}
          >
            <option selected disabled>
              Select Class
            </option>
            <option value="playgroup">Playgroup</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
          </select>
          <h4 className={Re.errormessage}>{studentClassErr}</h4>
        </div>
        <div className={Re.inputblock}>
          <label className={Re.formlabel}>Select Student</label>
          <select
            className={Re.selectblock}
            onChange={(event) => {
              setStudentEmail(event.target.value);
              getKits(event.target.value);
            }}
            value={studentEmail}
          >
            <option selected disabled>
              Select Student
            </option>
            {studentList.map((value, i) => {
              return (
                <option value={value.email} key={i}>
                  {value.name}
                </option>
              );
            })}
          </select>
          <h4 className={Re.errormessage}>{studentEmailErr}</h4>
        </div>
        <div className={Re.inputblock}>
          <label for="productname" className={Re.formlabel}>
            Product Name
          </label>
          <select
            className={Re.selectblock}
            onChange={(event) => setProductName(event.target.value)}
            defaultValue="Select Product"
            value={productName}
          >
            <option selected disabled>
              Select Product
            </option>
            {filteredList.map((param, i) => {
              return (
                <option value={param.kit_name} key={i}>
                  {param.kit_name}
                </option>
              );
            })}
          </select>
          <h4 className={Re.errormessage}>{productNameErr}</h4>
        </div>

        <div className={Re.inputblock}>
          <label for="returndate" className={Re.formlabel}>
            Return Date
          </label>
          <input
            type="date"
            className={Re.inputtext}
            onChange={(event) => setReturnDate(event.target.value)}
            value={returnDate}
          />
          <h4 className={Re.errormessage}>{returnDateErr}</h4>
        </div>
        <div className={Re.inputblock}>
          <label className={Re.formlabel}>Comments</label>
          <input
            type="textarea"
            className={Re.inputtext}
            value={comments}
            onChange={(event) => setComments(event.target.value)}
          />
          <h4 className={Re.errormessage}>{commentsErr}</h4>
        </div>
        <div className={Re.savebuttonholder}>
          <button className={Re.savebutton} onClick={() => validateForm()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReturnKit;

// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import swal from "sweetalert";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";
import AddSchoolIcon from "../../assets/icons/add-person.svg";

// Style imports
import Ad from "./addstudent.module.scss";

const useStyles = () => {
  return {
    backdrop: styled("div")({
      zIndex: 999,
      color: "#fff",
    }),
    dataGridRoot: styled("div")({
      "& .MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
      },
    }),
  };
};

const AddSchool = () => {
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.value);
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [reEnterPass, setReEnterPass] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [schoolNameErr, setSchoolNameErr] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [adminPhoneErr, setAdminPhoneErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [schoolCodeErr, setSchoolCodeErr] = useState("");
  const [reEnterPassErr, setReEnterPassErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [adminNameErr, setAdminNameErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [pinErr, setPinErr] = useState("");

  // Function to validate login form
  const validateForm = () => {
    console.log("userDetails", userDetails);
    let formIsValid = true;
    setSchoolNameErr("");
    setEmailErr("");
    setSchoolCodeErr("");
    setPasswordErr("");
    setReEnterPassErr("");

    if (schoolName === "" || schoolName === null || schoolName.trim() === "") {
      formIsValid = false;
      setSchoolNameErr("School Name cannot be empty");
      return;
    }
    if (adminEmail === "" || adminEmail === null || adminEmail.trim() === "") {
      formIsValid = false;
      setEmailErr("Email cannot be empty");
      return;
    }
    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPasswordErr("Password Cannot be empty");
      return;
    }
    if (
      reEnterPass === "" ||
      reEnterPass === null ||
      reEnterPass.trim() === ""
    ) {
      formIsValid = false;
      setReEnterPassErr("Please Re-enter the password");
      return;
    }
    if (reEnterPass !== password) {
      formIsValid = false;
      setReEnterPassErr("Password is not matching");
      return;
    }
    if (schoolCode === "" || schoolCode === null || schoolCode.trim() === "") {
      formIsValid = false;
      setSchoolCodeErr("School code cannot be empty");
      return;
    }

    if (adminName === "" || adminName === null || adminName.trim() === "") {
      formIsValid = false;
      setAdminNameErr("Admin Name cannot be empty");
    }

    if (adminPhone === "" || adminPhone === null || adminPhone.trim() === "") {
      formIsValid = false;
      setAdminPhoneErr("Contact cannot be empty");
      return;
    }

    if (city === "" || city === null || city.trim() === "") {
      formIsValid = false;
      setCityErr("Please enter the city");
      return;
    }

    if (state === "" || state === null || state.trim() === "") {
      formIsValid = false;
      setStateErr("Please enter the state");
      return;
    }

    if (pincode === "" || pincode === null || pincode.trim() === "") {
      formIsValid = false;
      setPincode("Please enter a pincode");
      return;
    }

    if (formIsValid === true) {
      console.log("form is valid");
      addSchool();
      return;
    }
  };

  const handleOnChange = (event) => {
    let files = event.target.files;
    console.log("files", files);
    setSelectedFile(files[0]);
  };

  const emptyForm = () => {
    setSchoolName("");
    setAdminName("");
    setAdminEmail("");
    setAdminPhone("");
    setCity("");
    setState("");
    setPincode("");
    setPassword("");
    setReEnterPass("");
    setSchoolCode("");
    setSelectedFile("");
  };

  //API to issue kits
  const addSchool = () => {
    setSpin(true);
    var formdata = new FormData();
    formdata.append("school_code", schoolCode);
    formdata.append("school_name", schoolName);
    formdata.append("admin_name", adminName);
    formdata.append("admin_email", adminEmail);
    formdata.append("password", password);
    formdata.append("password_re", reEnterPass);
    formdata.append("admin_phone", adminPhone);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", pincode);
    if (selectedFile !== "") {
      formdata.append("school_logo", selectedFile, selectedFile.name);
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${Constants.api_url}/schools/add`, requestOptions)
      .then((result) => {
        console.log("result", result);
        setSpin(false);
        if (result.status === 409) {
          swal({
            title: "Oops",
            text: "School already exist",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        } else if (result.status === 410) {
          swal({
            title: "Oops",
            text: "Password and Confirm Password don't match ",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        } else {
          swal({
            title: "Success",
            text: "School Added Successfully",
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              emptyForm();
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  return (
    <div className={Ad.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Ad.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Ad.backbutton} />
      </div>
      <div className={Ad.headingholder}>
        <img
          className={Ad.addstudenticon}
          src={AddSchoolIcon}
          alt="addstudenticon"
        />
        <h3 className={Ad.heading}>Add School</h3>
      </div>
      <div className={Ad.centerformholder}>
        <div className={Ad.inputblock}>
          <label for="schoolcode" className={Ad.formlabel}>
            School Code
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="schoolcode"
            onChange={(e) => setSchoolCode(e.target.value)}
            value={schoolCode}
          />
          <h4 className={Ad.errormessage}>{schoolCodeErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="schoolname" className={Ad.formlabel}>
            School Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="schoolname"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          <h4 className={Ad.errormessage}>{schoolNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label className={Ad.formlabel}>School logo</label>
          <input
            type="file"
            onChange={(event) => handleOnChange(event)}
            className={Ad.fileinput}
          />
        </div>
        <div className={Ad.inputblock} style={{ marginTop: "20px" }}>
          <label for="name" className={Ad.formlabel}>
            Admin Name
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="name"
            onChange={(e) => setAdminName(e.target.value)}
            value={adminName}
          />
          <h4 className={Ad.errormessage}>{adminNameErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="email" className={Ad.formlabel}>
            Admin Email
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="email"
            onChange={(e) => setAdminEmail(e.target.value)}
            value={adminEmail}
          />
          <h4 className={Ad.errormessage}>{emailErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="email" className={Ad.formlabel}>
            Password
          </label>
          <input
            type="password"
            className={Ad.inputtext}
            id="email"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <h4 className={Ad.errormessage}>{passwordErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="reenterpassword" className={Ad.formlabel}>
            Re-enter Password
          </label>
          <input
            type="password"
            className={Ad.inputtext}
            id="reenterpassword"
            onChange={(e) => setReEnterPass(e.target.value)}
            value={reEnterPass}
          />
          <h4 className={Ad.errormessage}>{reEnterPassErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="contact" className={Ad.formlabel}>
            Admin Phone
          </label>
          <input
            type="tel"
            className={Ad.inputtext}
            id="contact"
            onChange={(e) => setAdminPhone(e.target.value)}
            value={adminPhone}
          />
          <h4 className={Ad.errormessage}>{adminPhoneErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="city" className={Ad.formlabel}>
            City
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="city"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
          <h4 className={Ad.errormessage}>{cityErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="state" className={Ad.formlabel}>
            State
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="state"
            onChange={(e) => setState(e.target.value)}
            value={state}
          />
          <h4 className={Ad.errormessage}>{stateErr}</h4>
        </div>
        <div className={Ad.inputblock}>
          <label for="pincode" className={Ad.formlabel}>
            Pincode
          </label>
          <input
            type="text"
            className={Ad.inputtext}
            id="pincode"
            onChange={(e) => setPincode(e.target.value)}
            value={pincode}
          />
          <h4 className={Ad.errormessage}>{pinErr}</h4>
        </div>

        <div className={Ad.savebuttonholder}>
          <button
            className={Ad.savebutton}
            onClick={() => {
              validateForm();
            }}
          >
            Add School
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSchool;

// Dependencies imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import qs from "qs";
import { DataGrid } from "@mui/x-data-grid";
import Skeleton from "@mui/material/Skeleton";
import EditStudentModal from "./editStudentModal";

// Local imports
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as ActivateUser } from "../../assets/icons/activateuser.svg";
import { ReactComponent as DeactivateUser } from "../../assets/icons/deactivateuser.svg";
import { ReactComponent as EditUser } from "../../assets/icons/edituser.svg";
import { ReactComponent as DeleteUser } from "../../assets/icons/removeuser.svg";
import { authData } from "../../redux/auth/authSlice";
import { Constants } from "../../constants";
import SchoolDirectoryIcon from "../../assets/icons/directory.svg";

// Style imports
import Ad from "./addstudent.module.scss";
import ConfirmationModal from "./confirmationModal";

const RootContainer = styled("div")({
  display: "flex",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",
  "& .super-app-theme--header": {
    fontSize: "16px",
    color: "rgb(255,255,255)",
    backgroundColor: "#f05e13",
  },
});

const ModalContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
});
const BackdropContainer = styled("div")(({ theme }) => ({
  zIndex: theme?.zIndex?.drawer + 1,
  color: "#fff",
}));

const DataGridContainer = styled("div")({
  fontSize: "14px",
  fontFamily: "nunito",
  fontWeight: 400,
  color: "black",
  cursor: "pointer",
  background: "whitesmoke",
});

const SchoolDirectory = () => {
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.value);
  const [spin, setSpin] = useState(true);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [studentDetails, setStudentDetails] = useState();

  useEffect(() => {
    getDataGridData();
    //eslint-disable-next-line
  }, []);

  //   API to get graph and logs response
  const getDataGridData = () => {
    setSpin(true);
    var data = qs.stringify({});

    var config = {
      method: "GET",
      url: `${Constants.api_url}/users/students/directory/?school_code=${userDetails.school_code}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log("school directory", result.data);
        tableViewConvertion(result.data);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const handleEditStudentModalOpen = () => {
    setOpenEditModal(true);
  };

  const handleEditStudentModalClose = () => {
    setOpenEditModal(false);
  };

  const handleConfirmationModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleConfirmationModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleCellClick = (param, event) => {
    console.log("inside handlecell click");
    console.log("param value", param.value);
    if (param.value) {
      deactivateUser(param);
    } else {
      activateUser(param);
    }
  };

  const deactivateUser = (param) => {
    setSpin(true);
    var data = qs.stringify({});

    var config = {
      method: "PUT",
      url: `${Constants.api_url}/users/deactivate/user/${param.row.email}/${userDetails.school_code}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        getDataGridData();
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const activateUser = (param) => {
    setSpin(true);
    var data = qs.stringify({});

    var config = {
      method: "PUT",
      url: `${Constants.api_url}/users/reactivate/user/${param.row.email}/${userDetails.school_code}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        getDataGridData();
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const tableViewConvertion = (value) => {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.name = value[i];
      obj.email = value[i]["email"];
      obj.phone = value[i]["phone"];
      obj.class = value[i]["level"];

      obj.kitname =
        value[i]["pending_montlib_kits"] !== null
          ? value[i]["pending_montlib_kits"]["kit_name"]
          : "No Pending";
      obj.duedate =
        value[i]["pending_montlib_kits"] !== null
          ? moment(value[i]["pending_montlib_kits"]["due_date"]).format(
              "Do MMM YYYY"
            )
          : "--/--/----";
      obj.activate = value[i].is_active;
      obj.actions = value[i];
      trows.push(obj);
    }

    const tcolumns = [
      {
        field: "name",
        headerName: "Name",
        flex: 0.8,
        headerClassName: "super-app-theme--header",
        renderCell: (param) => {
          console.log("param inside name", param);
          return (
            <h3
              style={{ color: param.row.name["is_active"] ? "green" : "red" }}
            >
              {param.row.name.name}
            </h3>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1.2,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "class",
        headerName: "Class",
        flex: 0.5,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "kitname",
        headerName: "Pending Kit",
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "duedate",
        headerName: "Due Date",
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "activate",
        headerName: "Access",
        flex: 0.4,
        headerClassName: "super-app-theme--header",
        renderCell: (param) => {
          console.log("whats inside", param);
          return param.value ? (
            <DeactivateUser
              onClick={() => handleCellClick(param)}
              className={Ad.actionicons}
            />
          ) : (
            <ActivateUser
              onClick={() => handleCellClick(param)}
              className={Ad.actionicons}
            />
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 0.8,
        headerClassName: "super-app-theme--header",
        renderCell: (param) => {
          console.log("whats inside", param);
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1vw",
              }}
            >
              <EditUser
                className={Ad.actionicons}
                onClick={() => {
                  setStudentDetails(param.value);
                  handleEditStudentModalOpen();
                }}
              />
              <DeleteUser
                className={Ad.actionicons}
                onClick={() => {
                  setStudentDetails(param.value);
                  handleConfirmationModalOpen();
                }}
              />
            </div>
          );
        },
      },
    ];

    setRows(trows);
    setColumns(tcolumns);
    setSpin(false);
  };

  return (
    <div className={Ad.maincontainer}>
      {spin && (
        <BackdropContainer>
          <CircularProgress style={{ color: "#226bf6" }} />
        </BackdropContainer>
      )}

      <ModalContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openEditModal}
          onClose={handleEditStudentModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            invisible: true,
          }}
        >
          {/* <Fade in={openEditModal}> */}
          <EditStudentModal
            handleEditStudentModalClose={handleEditStudentModalClose}
            studentDetails={studentDetails}
            getDataGridData={getDataGridData}
          />
          {/* </Fade> */}
        </Modal>
      </ModalContainer>
      <ModalContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDeleteModal}
          onClose={handleConfirmationModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={openDeleteModal}> */}
          <ConfirmationModal
            handleConfirmationModalClose={handleConfirmationModalClose}
            Details={studentDetails}
            getDataGridData={getDataGridData}
            type={"user"}
          />
          {/* </Fade> */}
        </Modal>
      </ModalContainer>
      <div className={Ad.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={Ad.backbutton} />
      </div>
      <div className={Ad.headingholder}>
        <img
          className={Ad.addstudenticon}
          src={SchoolDirectoryIcon}
          alt="addstudenticon"
        />
        <h3 className={Ad.heading}>User Directory</h3>
      </div>
      {spin === false ? (
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#f05e13",
              color: "white",
            },
          }}
        >
          <DataGridContainer>
            <DataGrid
              rows={rows}
              columns={columns}
              rowHeight={50}
              disableSelectionOnClick
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "#f05e13",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
            />
          </DataGridContainer>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="100%" height={500} />{" "}
        </div>
      )}
    </div>
  );
};

export default SchoolDirectory;
